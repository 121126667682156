import { useState, useEffect } from 'react';
import { create } from 'zustand'
import { getPackages } from '../../modules/package/PackageCRUD';
import { RootState } from '../../../setup';
import { useSelector, shallowEqual } from 'react-redux'
import { PurchaseItem } from '../../../components/Purchase/PurchaseItem';
import Skeleton from 'react-loading-skeleton';
export interface Purchase {
    code: number;
    name: string;
    day: number;
    price: number;
    price_reccuring: number;
    quota: number;
    quota_use: number;
    type: number;
    value: number;
}

interface purchaseState {
    purchases: Purchase[]
    setPurchase: (purchases: Purchase[]) => void
}

export const usePurchaseStore = create<purchaseState>((set) => ({
    purchases: [],
    setPurchase: (purchases) => set({ purchases })
}))


const Purchases: React.FC = () => {
    const accessToken = useSelector<RootState, string>(
        ({ auth }) => auth.accessToken as string,
        shallowEqual
    );

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { purchases, setPurchase } = usePurchaseStore()

    useEffect(() => {
        setIsLoading(true)
        const fetchPurchases = async () => {
            try {
                const response = await getPackages(accessToken);
                if (response.data.success) {
                    setPurchase(response.data.data);
                    setIsLoading(false);
                } else {
                    throw new Error("Failed to fetch purchases");
                }
            } catch (error) {
                throw error;
            }
        }
        fetchPurchases();
    }, []);


    return (
        <div>
            <div className="d-flex justify-content-between h-100">
                <ol className="breadcrumb text-muted fs-6 fw-bold mb-5">
                    <li className="breadcrumb-item text-dark">Pembelian</li>
                </ol>
            </div>
            <div className="card card-custom card-flush">
                <div className="card-body row">
                    <div className="post d-flex flex-column-fluid" id="kt_post">
                        <div id="kt_content_container" className="container-xxl">
                            <div className="card" id="kt_pricing">
                                <div className="card-body p-lg-17">
                                    <div className="d-flex flex-column">
                                        <div className="mb-13 text-center">
                                            <h1 className="fs-2hx fw-bolder mb-5">Pembelian Paket Corporate</h1>
                                            <div className="text-gray-400 fw-bold fs-5">Jika Anda membutuhkan informasi lebih lanjut mengenai informasi terkait paket {window.location.host === 'www.otpwa.com' || window.location.host === 'localhost:3011' ? 'OTP Whatsapp' : 'WooBlazz Corporate'}. <br />Silahkan hubungi kami melalui&nbsp;
                                                <a href={`https://wa.me/6282330779799?text=Halo,%20saya%20ingin%20bertanya%20terkait%20paket%20${window.location.host === 'www.otpwa.com' || window.location.host === 'localhost:3011' ? 'OTP%20Whatsapp' : 'WooBlazz%20Corporate'}`} target="_blank" rel="noreferrer" className="link-primary fw-bolder">Customer Service</a> kami.</div>
                                        </div>
                                        <div className="row g-10">
                                            {
                                                purchases.map((purchase, index) => {
                                                    return (
                                                        purchase.type === 4 &&
                                                        <PurchaseItem purchase={purchase} key={index} />
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    )
}

export { Purchases }