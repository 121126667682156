import { FC, useEffect, useState } from "react";
import { KTSVG, toAbsoluteUrl } from "../../../_metronic/helpers";
import { History } from '../../../stores/UserBroadcastStore';
import { getChatLog } from "../../../app/modules/users/chat-log/ChatLogCRUD";
import { useLogStore } from "../../../stores/LogStore";
import moment from "moment";

interface LogProps {
    history: History
}

const Log: FC<LogProps> = ({ history }) => {

    const { logs, setLogs } = useLogStore()
    const [loading, setLoading] = useState(false);

    const fetchLog = async () => {
        setLoading(true);
        try {
            const response = await getChatLog(history.code);
            if (response.data.success) {
                setLogs(response.data.data);
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        // Fetch the log when the component is mounted
        console.log(history)
        fetchLog();

        // Event listener for when the modal is closed
        const modalElement = document.getElementById(`chat-history-log-${history.code}`);
        const handleModalClose = () => {
            setLogs([]); // Clear the logs state
        };

        modalElement?.addEventListener('hidden.bs.modal', handleModalClose);

        // Cleanup: Remove event listener on unmount
        return () => {
            modalElement?.removeEventListener('hidden.bs.modal', handleModalClose);
        };
    }, []);

    return (
        <div className="modal fade" tabIndex={-1} id={`chat-history-log-${history.code}`}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Chat Log</h5>
                        <div
                            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        >
                            <KTSVG
                                path="/media/icons/duotune/arrows/arr061.svg"
                                className="svg-icon svg-icon-2x"
                            />
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className="h-300px mh-300px overflow-auto rounded p-5"
                            style={{
                                background: "#efe7dd repeat",
                                backgroundImage: `url(${toAbsoluteUrl("/media/backgrounds/wa.jpg")})`,
                                position: "sticky",
                                fontFamily: "'Josefin Sans', sans-serif"
                            }}
                        >
                            {
                                loading &&
                                <div className="loading-overlay">
                                    <div className="loader"></div>
                                </div>
                            }
                            {
                                !loading &&
                                <div className={`chat sent2 mb-2`}>
                                    <span>{history.message}</span>
                                    <span className="metadata"><span className="time">{history.date_sent.split(' ')[3]}</span></span>
                                </div>
                            }

                            {logs.map((chat, index) => {
                                const previousChat = logs[index - 1];
                                let className = chat.sender_type === 0 ? "received" : "sent";

                                if (index > 0) {
                                    if (chat.sender_type === 0 && previousChat.sender_type === 0) {
                                        className = "received2";
                                    } else if (chat.sender_type === 1 && previousChat.sender_type === 1) {
                                        className = "sent2"; // Assuming you have a sent2 class, adjust as needed
                                    }
                                }

                                return (
                                    <div key={index}>
                                        <div className={`chat ${className} mb-2`}>
                                            <span>{chat.message}</span>
                                            <span className="metadata"><span className="time">
                                                {moment(chat.time).format('HH:mm')}
                                            </span></span>
                                        </div>
                                    </div>
                                );
                            })}

                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-light"
                            data-bs-dismiss="modal"
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div >
    )
}

export { Log }