import { KTSVG } from "../../../../_metronic/helpers"
import { User } from "../../../../interfaces/User"
import { useState, useEffect, useRef } from "react"
import { useSelector, shallowEqual } from "react-redux"
import { RootState } from "../../../../setup"
import { useUserStore } from "../../../../stores/UserStore"
import { customNumberFormat } from "../../../../function/general"
import Swal from "sweetalert2"
import { addCredit } from "../../../../app/modules/users/UserCRUD"
import { useCorporateUserStore } from "../../../../stores/CorporateUserStore"
import { Link } from 'react-router-dom'

interface ManageCreditProps {
    user: User
}

const AddToken: React.FC<ManageCreditProps> = ({ user }) => {
    const accessToken = useSelector<RootState, string>(
        ({ auth }) => auth.accessToken as string,
        shallowEqual
    );

    const { corporateUser, setCorporateUser } = useCorporateUserStore()
    const { users, setUsers } = useUserStore()
    const [credit, setCredit] = useState<number>(user.user_credit)
    const corporateCredit = corporateUser.data.credit_list.reduce((total, credit) => total + credit.credit_full, 0) - corporateUser.data.credit
    const [modalBtnLoading, setModalBtnLoading] = useState<boolean>(false)

    useEffect(() => {
        const modalElement = document.getElementById(`manage-credit-modal-${user.user_phone}`);
        const handleModalHidden = () => {
            setCredit(0);  // Or setCredit(user.user_credit) if you want to reset to initial value
        };

        modalElement?.addEventListener('hidden.bs.modal', handleModalHidden);

        // Clean up the event listener when the component is unmounted
        return () => {
            modalElement?.removeEventListener('hidden.bs.modal', handleModalHidden);
        };
    }, []);

    const inputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        if (!inputRef.current) return;
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting === false) {
                    // The input is not visible, clear its value
                    if (inputRef.current) {
                        inputRef.current.value = '';
                    }
                }
            },
            {
                root: null,
                threshold: 0,
            }
        );

        observer.observe(inputRef.current);

        return () => {
            observer.disconnect();
        };
    }, []);

    const injectCredit = async (phone: string, credit: number) => {
        setModalBtnLoading(true);
        if (corporateCredit < credit) {
            Swal.fire({
                title: 'Token Korporat Tidak Cukup!',
                icon: 'error',
                confirmButtonText: 'OK',
                customClass: {
                    confirmButton: 'btn btn-primary'
                },
                heightAuto: false,
            })
            setModalBtnLoading(false);
        } else {
            try {
                const response = await addCredit(credit, phone, accessToken);
                if (response.data.success) {
                    setTimeout(() => {
                        setModalBtnLoading(false);
                        const button = document.getElementById(`btnCancelManage-${phone}`);
                        setCorporateUser({
                            data: {
                                ...corporateUser.data,
                                credit: corporateUser.data.credit + credit
                            }
                        })
                        setUsers(users.map((users) => {
                            if (users.user_phone === phone) {
                                users.user_credit += credit;
                            }
                            return users;
                        }))
                        button?.click();
                        Swal.fire({
                            title: 'Berhasil mengatur token!',
                            html: `<span class="fw-bolder" style="color: red;">Masa aktif token agent adalah 45 hari setelah ditambahkan!</span>`,
                            icon: 'success',
                            confirmButtonText: 'OK',
                            customClass: {
                                confirmButton: 'btn btn-primary'
                            },
                            heightAuto: false,
                        })

                    }, 1000);
                }
            } catch (error) {
                setTimeout(() => {
                    Swal.fire({
                        title: 'Gagal mengatur token!',
                        icon: 'error',
                        confirmButtonText: 'OK',
                        customClass: {
                            confirmButton: 'btn btn-primary'
                        },
                        heightAuto: false,
                    })
                    setModalBtnLoading(false)
                }, 1000);
            }
        }
    }

    return (
        <>
            <button type="button"
                className="btn btn-info"
                data-bs-toggle="modal"
                data-bs-target={`#manage-credit-modal-${user.user_phone}`}
            >
                Tambah Token
            </button>
            <div className="modal fade" tabIndex={-1} id={`manage-credit-modal-${user.user_phone}`}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title">Tambah Token</h3>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="card shadow-sm p-3 mb-5 bg-white rounded">
                                <div className="row g-0">
                                    <div className="col-12">
                                        <div className="card-body">
                                            <h5 className="card-title fw-bolder">{user.user_name}</h5>
                                            <p className="card-text">{user.user_phone}</p>
                                            <div className="my-5">
                                                <label className="form-label">Token Agen</label>
                                                <input
                                                    type="tel"
                                                    ref={inputRef}
                                                    autoFocus={true}
                                                    className="form-control form-control-solid w-50"
                                                    onChange={e => {
                                                        if (/^\d*$/.test(e.target.value)) {
                                                            setCredit(Number(e.target.value));
                                                        }
                                                    }}
                                                    onInput={(event) => {
                                                        // Replace non-digits
                                                        event.currentTarget.value = event.currentTarget.value.replace(/[^0-9]/g, '');

                                                        // Remove leading zeros
                                                        while (event.currentTarget.value.startsWith("0")) {
                                                            event.currentTarget.value = event.currentTarget.value.substring(1);
                                                        }
                                                    }}
                                                />
                                                <label className="form-label">*Masa aktif 45 hari</label>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-md-4">
                                        <img src="media/logos/logo-wooblazz.png" className="img-fluid rounded-start" alt="..." />
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer d-flex justify-content-between">
                            <div>
                                <h5 className="fw-bolder">Token Korporat : {customNumberFormat(corporateCredit)}</h5>
                            </div>
                            <div>
                                <button
                                    type="button"
                                    className="btn btn-light me-3"
                                    data-bs-dismiss="modal"
                                    id={`btnCancelManage-${user.user_phone}`}
                                >
                                    Batal
                                </button>
                                <button type="button" className={`btn btn-primary me-10 ${modalBtnLoading ? "disabled" : ""}`}
                                    onClick={() => injectCredit(user.user_phone, credit)}
                                    data-kt-indicator={modalBtnLoading ? 'on' : 'off'}
                                >
                                    <span className="indicator-label">Tambah</span>
                                    <span className="indicator-progress">Harap tunggu...
                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export { AddToken }