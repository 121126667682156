import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import 'moment/locale/id';
import { Link } from "react-router-dom";
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import { ChatHistory } from "../../../../components/ChatHistory";
import { useUserStore } from "../../../../stores/UserStore";
import { customNumberFormat } from "../../../../function/general";

export declare type ValueType = [Date?, Date?];
export declare type DateRange = [Date, Date];
export interface RangeType {
    label: React.ReactNode;
    value: DateRange | ((value?: ValueType) => DateRange);
    closeOverlay?: boolean;
    placement?: 'bottom' | 'left';
}

const predefinedRanges: RangeType[] = [
    {
        label: 'Hari ini',
        value: [new Date(), new Date()],
        placement: 'left'
    },
    {
        label: 'Kemarin',
        value: [addDays(new Date(), -1), addDays(new Date(), -1)],
        placement: 'left'
    },
    {
        label: '7 Hari Lalu',
        value: [subDays(new Date(), 7), new Date()],
        placement: 'left'
    },
    {
        label: '30 Hari Lalu',
        value: [subDays(new Date(), 30), new Date()],
        placement: 'left'
    },
    {
        label: 'Minggu Ini',
        value: [
            startOfWeek(new Date(), { weekStartsOn: 1 }),  // Start of the week: Monday
            new Date() > endOfWeek(new Date(), { weekStartsOn: 1 })
                ? endOfWeek(new Date(), { weekStartsOn: 1 })
                : new Date()  // If today is after the end of the week, use end of week. Otherwise, use today.
        ],
        placement: 'left'
    },
    {
        label: 'Bulan Ini',
        value: [
            startOfMonth(new Date()),  // Start of the month
            new Date() > endOfMonth(new Date())
                ? endOfMonth(new Date())
                : new Date()  // If today is after the end of the month, use end of month. Otherwise, use today.
        ],
        placement: 'left'
    },
    {
        label: 'Minggu Lalu',
        value: [
            startOfWeek(subDays(new Date(), 7), { weekStartsOn: 1 }),  // Monday is 1
            endOfWeek(subDays(new Date(), 7), { weekStartsOn: 1 })  // Monday is 1
        ],
        placement: 'left'
    },
    {
        label: 'Bulan Lalu',
        value: [
            startOfMonth(subDays(new Date(), 30)),
            endOfMonth(subDays(new Date(), 30))
        ],
        placement: 'left'
    }

];

const today = new Date();

const Detail: React.FC = () => {
    const { phone } = useParams();

    const { users } = useUserStore()

    const user = users.find(user => user.user_phone === atob(phone!))

    const [startDate, setStartDate] = useState<Date>(today);
    const [endDate, setEndDate] = useState<Date>(today);
    const [loading, setLoading] = useState<boolean>(true);

    const [isMobile, setIsMobile] = useState<boolean>(false);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 666) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        };

        // Initial check
        handleResize();

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    setTimeout(() => {
        setLoading(false)
    }, 3000);

    return (
        <div id="kt_content_container" className="container-xxl">
            <div className="d-flex justify-content-between mb-5">
                <ol className="breadcrumb text-muted fs-6 fw-bold mb-5">
                    <li className="breadcrumb-item pe-3">
                        <Link to={`/users`} className="pe-3">
                            Agen
                        </Link>
                    </li>
                    <li className="breadcrumb-item text-dark">Detail</li>
                </ol>
                <div>
                    <DateRangePicker
                        cleanable={false}
                        character=" - "
                        format="dd MMM yyyy"
                        locale={
                            {
                                sunday: 'Mg',
                                monday: 'Sn',
                                tuesday: 'Sl',
                                wednesday: 'Rb',
                                thursday: 'Km',
                                friday: 'Jm',
                                saturday: 'Sb',
                                ok: 'Gunakan',
                            }
                        }
                        value={[startDate, endDate]}
                        ranges={predefinedRanges}
                        placement="bottomEnd"
                        shouldDisableDate={(date) => {
                            return date > today;
                        }}
                        onChange={(value) => {
                            if (value && value.length === 2) {
                                setStartDate(value[0]);
                                setEndDate(value[1]);
                            }
                        }}
                        showOneCalendar={isMobile}
                        disabled={loading}
                        isoWeek={true}
                    />
                </div>
            </div>
            <div className="card card-custom mb-5">
                <div className="card-header">
                    <h3 className="card-title">
                        {
                            user?.user_name ? user?.user_name + ' - ' + user?.user_phone : user?.user_phone
                        }
                    </h3>
                </div>
                <div className="card-body">
                    <h5>
                        Kredit: {customNumberFormat(user!.user_credit)}
                    </h5>
                </div>
            </div>
            <div className="card">
                <div className="card-header card-header-stretch w-100 min-h-50px">
                    <div className="card-toolbar w-100 d-flex">
                        <ul className="nav nav-tabs nav-line-tabs nav-line-tabs-2x nav-stretch fs-6 border-0 w-100 justify-content-around">
                            <li className="nav-item">
                                <a className="nav-link active text-active-primary fw-bold" data-bs-toggle="tab" href="#history_tab_pane">Riwayat Pesan</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="card-body pt-0 mt-0">
                    <div className="tab-content" id="myTabContent">
                        <ChatHistory user={user!} startDate={startDate} endDate={endDate} />
                    </div>
                </div>
            </div >
        </div>
    )
}

export { Detail }