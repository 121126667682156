import { DataTableDataRow as DataRow } from "../interfaces/DataTableDataRow";
import moment from "moment";
import 'moment/locale/id';
import * as ExcelJS from 'exceljs';
import { User } from "../interfaces/User";
import { History } from "../stores/UserBroadcastStore";

export const exportExcel = (filteredData: History[], filterText: string, startDate: Date, endDate: Date, user: User, selectedValue: string) => {
    let headerRow = 4;

    const dataToExport: DataRow[] = Array.from(filteredData).map((row, index) => ({
        no: index + 1,
        name: row.name,
        phone: row.phone,
        message: row.message,
        status: getStatusText(row.status),
    }));

    const workbook = createWorkbook();
    const worksheet = createWorksheet(workbook);

    worksheet.mergeCells('A1', 'E1')
    worksheet.mergeCells('A2', 'E2')
    worksheet.mergeCells('A3', 'E3')

    worksheet.getCell('A1').value = `Data Broadcast`;
    const dateStart = moment(startDate).format('DD MMMM YYYY');
    const dateEnd = moment(endDate).format('DD MMMM YYYY');
    worksheet.getCell('A2').value = dateStart === dateEnd ? dateStart : `${dateStart} - ${dateEnd}`;
    worksheet.getCell('A3').value = `Pengirim Broadcast: ${user.user_name} - ${user.user_phone}`;

    if (selectedValue === 'Berhasil' || selectedValue === 'Gagal') {
        worksheet.mergeCells(`A${headerRow}`, `E${headerRow}`);
        worksheet.getCell(`A${headerRow}`).value = `Filter Status: ${selectedValue}`;
        headerRow++;
    }
    if (filterText) {
        worksheet.mergeCells(`A${headerRow}`, `E${headerRow}`);
        worksheet.getCell(`A${headerRow}`).value = `Filter: ${filterText}`;
        headerRow++;
    }

    worksheet.getRow(headerRow).values = ['No', 'Nama Kontak', 'Nomor Whatsapp', 'Konten', 'Status'];

    addDataRows(worksheet, dataToExport, headerRow); // Use headerRow for adding data rows
    applyStyles(worksheet, headerRow, selectedValue, filterText);
    setColumnWidths(worksheet);

    exportWorkbook(workbook, 'Riwayat Pesan.xlsx');
};

const getStatusText = (status: number): string => {
    switch (status) {
        case 1:
            return 'Berhasil';
        case 3:
            return 'Gagal';
        case 4:
            return 'Nomor tidak valid';
        case 5:
            return 'Melebihi kuota';
        case 6:
            return 'Melebihi limit harian';
        case 7:
            return 'Tidak terhubung dgn WA';
        case 8:
            return 'User berhenti berlangganan';
        default:
            return 'Pending';
    }
};

const createWorkbook = () => {
    const workbook = new ExcelJS.Workbook();
    workbook.creator = window.location.host === 'www.otpwa.com' || window.location.host === 'localhost:3011' ? 'OTPWA' : 'Wooblazz';
    workbook.lastModifiedBy = window.location.host === 'www.otpwa.com' || window.location.host === 'localhost:3011' ? 'OTPWA' : 'Wooblazz';
    workbook.created = new Date();
    workbook.modified = new Date();
    return workbook;
};

const createWorksheet = (workbook: ExcelJS.Workbook) => {
    const worksheet = workbook.addWorksheet('Sheet1');
    return worksheet;
};

const addDataRows = (worksheet: ExcelJS.Worksheet, data: DataRow[], headerRow: number) => {
    data.forEach((rowData, index) => {
        const rowIndex = index + headerRow + 1;
        worksheet.getCell(`A${rowIndex}`).value = rowData.no;
        worksheet.getCell(`B${rowIndex}`).value = rowData.name;
        worksheet.getCell(`C${rowIndex}`).value = rowData.phone;
        worksheet.getCell(`D${rowIndex}`).value = rowData.message;
        worksheet.getCell(`E${rowIndex}`).value = rowData.status;
    });
};

const applyStyles = (worksheet: ExcelJS.Worksheet, headerRow: number, selectedValue: string, filterText: string) => {
    worksheet.getColumn(4).alignment = {
        vertical: 'top',
        horizontal: 'left',
        wrapText: true,
    };

    worksheet.getColumn(1).alignment = {
        vertical: 'top',
        horizontal: 'center',
    };

    worksheet.getColumn(2).alignment = {
        vertical: 'top',
        horizontal: 'left',
    };

    worksheet.getColumn(3).alignment = {
        vertical: 'top',
        horizontal: 'left',
    };

    worksheet.getColumn(5).alignment = {
        vertical: 'top',
        horizontal: 'left',
    };

    worksheet.getCell('A1').font = {
        name: 'Arial Black',
        size: 16,
        bold: true,
    };

    worksheet.getCell('A1').alignment = {
        vertical: 'middle',
        horizontal: 'center',
    };

    worksheet.getCell('A2').font = {
        name: 'Arial Black',
        size: 12,
        bold: true,
    };

    worksheet.getCell('A2').alignment = {
        vertical: 'middle',
        horizontal: 'center',
    };

    worksheet.getCell('A3').alignment = {
        vertical: 'middle',
        horizontal: 'center',
    };

    if (selectedValue !== '' && filterText !== '') {
        worksheet.getCell('A4').alignment = {
            vertical: 'middle',
            horizontal: 'center',
        };
        worksheet.getCell('A5').alignment = {
            vertical: 'middle',
            horizontal: 'center',
        };
    } else if (selectedValue !== '' || filterText !== '') {
        worksheet.getCell('A4').alignment = {
            vertical: 'middle',
            horizontal: 'center',
        };
    }

    worksheet.getRow(headerRow).font = {
        name: 'Arial Black',
        size: 12,
        bold: true,
    };

    worksheet.getRow(headerRow).alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true,
    };

    worksheet.getRow(headerRow).eachCell((cell) => {
        cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
                argb: '66B2FF',
            },
        };
    });
};

const setColumnWidths = (worksheet: ExcelJS.Worksheet) => {
    const colWidths = [5, 20, 20, 35, 20];
    worksheet.columns = colWidths.map((width) => ({ width }));
};

const exportWorkbook = (workbook: ExcelJS.Workbook, fileName: string) => {
    workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();
    });
};