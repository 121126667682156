import axios from 'axios'
import { UserModel } from '../models/UserModel'
import Bowser from 'bowser'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}wzcorp/verify_token`
export const LOGIN_URL = `${API_URL}wzcorp/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`
export const CHANGE_PASSWORD_URL = `${API_URL}/wzcorp/change_password`
export const UPDATE_PROFILE = `${API_URL}/wzcorp/update_profile`

// Server should return AuthModel
export function login(username: string, password: string) {
  return axios.post(LOGIN_URL, {
    username,
    password,
    platform: navigator.platform,
    device: Bowser.getParser(window.navigator.userAgent).getBrowserName(),
    version: Bowser.getParser(window.navigator.userAgent).getBrowserVersion(),
  })
}

// Server should return AuthModel
export function register(email: string, firstname: string, lastname: string, password: string, password_confirmation: string) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
    email
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {}, {
    headers: {
      'x-auth-token': token
    }
  })
}

export function changePassword(password_old: string, password_new: string, token: string) {
  return axios.post(CHANGE_PASSWORD_URL,
    {
      password_old,
      password_new
    },
    {
      headers: {
        'x-auth-token': token
      }
    }
  )
}

export function updateProfile(user_name: string, user_phone: string, user_address: string, token: string) {
  return axios.post(UPDATE_PROFILE,
    {
      user_name,
      user_phone,
      user_address
    },
    {
      headers: {
        'x-auth-token': token
      }
    }
  )
}