import { FC, useEffect, useState } from "react";
import { Link, Element, Events, scrollSpy } from 'react-scroll';
import { Link as RouteLink, useParams } from 'react-router-dom';
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { base64encrypt, encryptRot13 } from "../../../../function/encrypt";
import axios from "axios";
import { useUserStore } from "../../../../stores/UserStore";
import { Documentation } from "../../../../components/OpenAPI/Documentation";
import { QueryParameter } from "../../../../interfaces/QueryParameter";
import { Code } from "../../../../components/OpenAPI/Code";

// SEND MESSAGE
const sendExample = {
    status: 200,
    message: 'success'
}

const sendQueryParameters: QueryParameter[] = [
    {
        field: 'to',
        type: 'String (Required)',
        description: 'Nomor penerima pesan (format: "628xxxxxxxxxx").'
    },
    {
        field: 'type',
        type: 'String (Required)',
        description: 'Jenis pesan yang akan dikirim. Pilihan: "chat", "image", "video", "file".'
    },
    {
        field: 'message',
        type: 'String (Required)',
        description: 'Isi pesan teks (hanya berlaku jika tipe adalah "chat").'
    },
    {
        field: 'url',
        type: 'String (Required)',
        description: 'Sumber konten (gambar, video, atau file) yang akan dikirim (hanya berlaku jika tipe adalah "image", "video", atau "file").'
    }
]

// CHECK NUMBER
const checkExample = {
    success: true,
    status: 200,
    message: {
        exist: true
    }
}

const checkQueryParameters: QueryParameter[] = [
    {
        field: 'number',
        type: 'String (Required)',
        description: 'Nomor yang akan dicek (format: "628xxxxxxxxxx").'
    }
]

// MEDIA DOWNLOADER
const mediaExample = {
    success: true,
    status: 200,
    message: "File Already Exist",
    url_media: "https://resource.looyal.id/api-wa/chat-attachment/WA_IMAGE_1701414384592_7682.jpg"
}

const mediaQueryParameters: QueryParameter[] = [
    {
        field: 'messageId',
        type: 'String (Required)',
        description: 'ID dari pesan whatsapp.'
    }
]

// RETRIEVE MESSAGE
const retrieveQueryParameters: QueryParameter[] = [
    {
        field: 'chat_id',
        type: 'String (Required)',
        description: 'ID unique setiap pesan'
    },
    {
        field: 'chat_user',
        type: 'String (Required)',
        description: 'Nomor WA pengguna'
    },
    {
        field: 'chat_participant',
        type: 'String (Required)',
        description: 'Nomor WA participant'
    },
    {
        field: 'chat_from_me',
        type: 'Integer (Required)',
        description: 'Bernilai 1 jika pesan berasal dari pengguna, bernilai 0 jika pesan berasal dari participant'
    },
    {
        field: 'chat_name',
        type: 'String (Required)',
        description: 'Nama dari pemilik nomor WA pengirim pesan'
    },
    {
        field: 'chat_message',
        type: 'String (Required)',
        description: 'Pesan yang dikirimkan (dalam bentuk enkripsi dengan algoritma aes-256-cbc, KEY dan IV akan dikirimkan pada user untuk dapat melakukan dekripsi)'
    },
    {
        field: 'chat_attachment',
        type: 'String (Required)',
        description: 'enkripsi dari attachment yang dikirimkan dalam bentuk file (untuk dapat melihat isi attachment gunakan API media downloader)'
    },
    {
        field: 'chat_attachment_name',
        type: 'String (Required)',
        description: 'Nama dari file attachment yang dikirim'
    },
]

// GENERATE QR WHATSAPP
const generateQrExampleAlready = {
    success: true,
    status: 200,
    message: "Already Connected"
}

const generateQrExampleSuccess = {
    success: true,
    status: 200,
    message: "Success Generate Qr Code, Qr Code Expired in 15 seconds",
    data: "<base64 image>"
}

// CHECK SESSION
const checkSessionExample = {
    success: true,
    status: 200,
    data: {
        "number": "6287714041231",
        "status": true
    }
}

// SET WEBHOOK INBOUND
const setWebhookExample = {
    success: true,
    status: 200,
    data: {
        "key": "xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx",
        "iv": "xxxxxxxxxxxxxxxx"
    }
}

const setWebhookQueryParameters: QueryParameter[] = [
    {
        field: 'endpoint_url',
        type: 'String (Required)',
        description: 'URL endpoint untuk menerima chat dengan parameter seperti',
        target: "retrieveMessage",
        target_name: "Retrieve Message"
    },
]

const headerInfoStyle = { color: "#235C97", fontWeight: "bold" }

const OpenAPI: FC = () => {
    const { code } = useParams();

    const { users } = useUserStore()

    const agent = users.find((item) => item.corporate_user_code === atob(code!))

    const [apiKey, setApiKey] = useState('')
    const [logo, setLogo] = useState("");

    const headDescription = document.querySelector("meta[name='description']");
    const headFavicon = document.querySelector("link[rel='shortcut icon']");

    // ===================== SEND MESSAGE =====================
    const python_send = `
    import requests
    import json

    reqUrl = "https://${window.location.host === 'www.otpwa.com' || window.location.host === 'localhost:3011' ? 'api.otpwa.com' : 'api.wooblazz.com'}/o1/send"

    apiKey = "${apiKey}"

    headersList = {
    "Accept": "*/*",
    "Content-Type": "application/json", 
    "Authorization": apiKey
    }

    payload = json.dumps({
    "to": "<<to>>",
    "type": "video",
    "message": "Sending a video",
    "url": "https://example.com/video.mp4"
    })

    response = requests.request("POST", reqUrl, data=payload, headers=headersList)

    print(response.text)
    `

    const javascript_send = `
    const axios = require('axios');

    const reqUrl = "https://${window.location.host === 'www.otpwa.com' || window.location.host === 'localhost:3011' ? 'api.otpwa.com' : 'api.wooblazz.com'}/o1/send";

    const headersList = {
    "Accept": "*/*",
    "Content-Type": "application/json",
    "Authorization": "${apiKey}" 
    };

    const payload = {
    to: "<<to>>",
    type: "video",
    message: "Sending a video",
    url: "https://example.com/video.mp4"
    };

    axios.post(reqUrl, payload, { headers: headersList })
    .then((response) => {
        console.log(response.data);
    })
    .catch((error) => {
        console.error(error);
    });
    `

    const php_send = `
    <?php

    $reqUrl = "https://${window.location.host === 'www.otpwa.com' || window.location.host === 'localhost:3011' ? 'api.otpwa.com' : 'api.wooblazz.com'}/o1/send";

    $headersList = [
        "Accept: */*",
        "Content-Type: application/json",
        "Authorization: ${apiKey}"
    ];

    $data = [
        "to" => "<<to>>",
        "type" => "video",
        "message" => "Sending a video",
        "url" => "https://example.com/video.mp4"
    ];

    $ch = curl_init($reqUrl);
    curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);
    curl_setopt($ch, CURLOPT_HTTPHEADER, $headersList);
    curl_setopt($ch, CURLOPT_POSTFIELDS, json_encode($data));

    $response = curl_exec($ch);

    if ($response === false) {
        echo "Error: " . curl_error($ch);
    } else {
        echo $response;
    }

    curl_close($ch);
    ?>
    `

    const bash_send = `
    curl -X POST "https://${window.location.host === 'www.otpwa.com' || window.location.host === 'localhost:3011' ? 'api.otpwa.com' : 'api.wooblazz.com'}/o1/send" 
    -H "Accept: */*" 
    -H "Content-Type: application/json" 
    -H "Authorization: ${apiKey}"
    -d '{
    "to": "6282133164875",
    "type": "video",
    "message": "Ini kirim video",
    "url": "https://dufol.com/asdasd.mp4"
    }'
    `


    // ===================== CHECK NUMBER =====================
    const python_check = `
    import requests
    import json

    reqUrl = "https://${window.location.host === 'www.otpwa.com' || window.location.host === 'localhost:3011' ? 'api.otpwa.com' : 'api.wooblazz.com'}/o1/check_number"

    apiKey = "${apiKey}"

    headersList = {
    "Accept": "*/*",
    "Content-Type": "application/json", 
    "Authorization": apiKey
    }

    payload = json.dumps({
    "number": "<<number>>"
    })

    response = requests.request("POST", reqUrl, data=payload, headers=headersList)

    print(response.text)
    `

    const javascript_check = `
    const axios = require('axios');

    const reqUrl = "https://${window.location.host === 'www.otpwa.com' || window.location.host === 'localhost:3011' ? 'api.otpwa.com' : 'api.wooblazz.com'}/o1/check_number";

    const headersList = {
    "Accept": "*/*",
    "Content-Type": "application/json",
    "Authorization": "${apiKey}" 
    };

    const payload = {
    number: "<<number>>"
    };

    axios.post(reqUrl, payload, { headers: headersList })
    .then((response) => {
        console.log(response.data);
    })
    .catch((error) => {
        console.error(error);
    });
    `

    const php_check = `
    <?php

    $reqUrl = "https://${window.location.host === 'www.otpwa.com' || window.location.host === 'localhost:3011' ? 'api.otpwa.com' : 'api.wooblazz.com'}/o1/check_number";

    $headersList = [
        "Accept: */*",
        "Content-Type: application/json",
        "Authorization: ${apiKey}"
    ];

    $data = [
        "number" => "<<number>>"
    ];

    $ch = curl_init($reqUrl);
    curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);
    curl_setopt($ch, CURLOPT_HTTPHEADER, $headersList);
    curl_setopt($ch, CURLOPT_POSTFIELDS, json_encode($data));

    $response = curl_exec($ch);

    if ($response === false) {
        echo "Error: " . curl_error($ch);
    } else {
        echo $response;
    }

    curl_close($ch);
    ?>
    `

    const bash_check = `
    curl  -X POST
    'https://${window.location.host === 'www.otpwa.com' || window.location.host === 'localhost:3011' ? 'api.otpwa.com' : 'api.wooblazz.com'}/o1/check_number'
    --header 'Accept: */*'
    --header 'Content-Type: application/json'
    --header 'Authorization: ${apiKey}'
    --data-raw '{
    "number": "6282133164875"
    }'
    `


    // ===================== MEDIA DOWNLOADER =====================
    const python_media = `
    import requests
    import json

    reqUrl = "https://${window.location.host === 'www.otpwa.com' || window.location.host === 'localhost:3011' ? 'api.otpwa.com' : 'api.wooblazz.com'}/o1/download-media"

    apiKey = "${apiKey}"

    headersList = {
    "Accept": "*/*",
    "Content-Type": "application/json", 
    "Authorization": apiKey
    }

    payload = json.dumps({
    "messageId": "1E7931156B7116A47E258500C005BA5E"
    })

    response = requests.request("POST", reqUrl, data=payload, headers=headersList)

    print(response.text)
    `

    const javascript_media = `
    const axios = require('axios');

    const reqUrl = "https://${window.location.host === 'www.otpwa.com' || window.location.host === 'localhost:3011' ? 'api.otpwa.com' : 'api.wooblazz.com'}/o1/download-media";

    const headersList = {
    "Accept": "*/*",
    "Content-Type": "application/json",
    "Authorization": "${apiKey}" 
    };

    const payload = {
    messageId: "1E7931156B7116A47E258500C005BA5E"
    };

    axios.post(reqUrl, payload, { headers: headersList })
    .then((response) => {
        console.log(response.data);
    })
    .catch((error) => {
        console.error(error);
    });
    `

    const php_media = `
    <?php

    $reqUrl = "https://${window.location.host === 'www.otpwa.com' || window.location.host === 'localhost:3011' ? 'api.otpwa.com' : 'api.wooblazz.com'}/o1/download-media";

    $headersList = [
        "Accept: */*",
        "Content-Type: application/json",
        "Authorization: ${apiKey}"
    ];

    $data = [
        "messageId" => "1E7931156B7116A47E258500C005BA5E"
    ];

    $ch = curl_init($reqUrl);
    curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);
    curl_setopt($ch, CURLOPT_HTTPHEADER, $headersList);
    curl_setopt($ch, CURLOPT_POSTFIELDS, json_encode($data));

    $response = curl_exec($ch);

    if ($response === false) {
        echo "Error: " . curl_error($ch);
    } else {
        echo $response;
    }

    curl_close($ch);
    ?>
    `

    const bash_media = `
    curl  -X POST
    'https://${window.location.host === 'www.otpwa.com' || window.location.host === 'localhost:3011' ? 'api.otpwa.com' : 'api.wooblazz.com'}/o1/download-media'
    --header 'Accept: */*'
    --header 'Content-Type: application/json'
    --header 'Authorization: ${apiKey}'
    --data-raw '{
    "messageId": "1E7931156B7116A47E258500C005BA5E"
    }'
    `


    // ===================== RETRIEVE MESSAGE =====================
    const python_retrieve = `
    request = {
    "chat_id": "3A56F7194B3EA106CBC0",
    "chat_user": "${agent?.user_phone}",
    "chat_participant": "62xxxxxxxxxxx",
    "chat_from_me": 1,
    "chat_name": "${agent?.user_name}",
    "chat_message": "dea91e4d7e0fa5de36b5811a22d26943",
    "chat_attachment": "dcb8a96a772d7485351c7bfee48cd14db22663074646f2c7ad7853638b45c37a932a5bedc1f4eb25c10ec9096bd036a2e33b4061024fabd5bd15d09bd3e740a5",
    "chat_attachment_name": "testing.png"
    }
    `

    const javascript_retrieve = `
    request = {
    "chat_id": "3A56F7194B3EA106CBC0",
    "chat_user": "${agent?.user_phone}",
    "chat_participant": "62xxxxxxxxxxx",
    "chat_from_me": 1,
    "chat_name": "${agent?.user_name}",
    "chat_message": "dea91e4d7e0fa5de36b5811a22d26943",
    "chat_attachment": "dcb8a96a772d7485351c7bfee48cd14db22663074646f2c7ad7853638b45c37a932a5bedc1f4eb25c10ec9096bd036a2e33b4061024fabd5bd15d09bd3e740a5",
    "chat_attachment_name": "testing.png"
    }
    `

    const php_retrieve = `
    request = {
    "chat_id": "3A56F7194B3EA106CBC0",
    "chat_user": "${agent?.user_phone}",
    "chat_participant": "62xxxxxxxxxxx",
    "chat_from_me": 1,
    "chat_name": "${agent?.user_name}",
    "chat_message": "dea91e4d7e0fa5de36b5811a22d26943",
    "chat_attachment": "dcb8a96a772d7485351c7bfee48cd14db22663074646f2c7ad7853638b45c37a932a5bedc1f4eb25c10ec9096bd036a2e33b4061024fabd5bd15d09bd3e740a5",
    "chat_attachment_name": "testing.png"
    }
    `

    const bash_retrieve = `
    request = {
    "chat_id": "3A56F7194B3EA106CBC0",
    "chat_user": "${agent?.user_phone}",
    "chat_participant": "62xxxxxxxxxxx",
    "chat_from_me": 1,
    "chat_name": "${agent?.user_name}",
    "chat_message": "dea91e4d7e0fa5de36b5811a22d26943",
    "chat_attachment": "dcb8a96a772d7485351c7bfee48cd14db22663074646f2c7ad7853638b45c37a932a5bedc1f4eb25c10ec9096bd036a2e33b4061024fabd5bd15d09bd3e740a5",
    "chat_attachment_name": "testing.png"
    }
    `


    // ===================== GENERATE QR WHATSAPP =====================
    const python_generate_qr = `
    import requests
    import json

    reqUrl = "https://${window.location.host === 'www.otpwa.com' || window.location.host === 'localhost:3011' ? 'api.otpwa.com' : 'api.wooblazz.com'}/o1/generate/qr"

    apiKey = "${apiKey}"

    headersList = {
    "Accept": "*/*",
    "Content-Type": "application/json", 
    "Authorization": apiKey
    }

    response = requests.request("GET", reqUrl, headers=headersList)

    print(response.text)
    `

    const javascript_generate_qr = `
    const axios = require('axios');

    const reqUrl = "https://${window.location.host === 'www.otpwa.com' || window.location.host === 'localhost:3011' ? 'api.otpwa.com' : 'api.wooblazz.com'}/o1/generate/qr";

    const headersList = {
    "Accept": "*/*",
    "Content-Type": "application/json",
    "Authorization": "${apiKey}" 
    };

    axios.get(reqUrl, { headers: headersList })
    .then((response) => {
        console.log(response.data);
    })
    .catch((error) => {
        console.error(error);
    });
    `

    const php_generate_qr = `
    <?php

    $reqUrl = "https://${window.location.host === 'www.otpwa.com' || window.location.host === 'localhost:3011' ? 'api.otpwa.com' : 'api.wooblazz.com'}/o1/generate/qr";

    $headersList = [
        "Accept: */*",
        "Content-Type: application/json",
        "Authorization: ${apiKey}"
    ];

    $ch = curl_init($reqUrl);
    curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);
    curl_setopt($ch, CURLOPT_HTTPHEADER, $headersList);

    $response = curl_exec($ch);

    if ($response === false) {
        echo "Error: " . curl_error($ch);
    } else {
        echo $response;
    }

    curl_close($ch);
    ?>
    `

    const bash_generate_qr = `
    curl  -X GET
    'https://${window.location.host === 'www.otpwa.com' || window.location.host === 'localhost:3011' ? 'api.otpwa.com' : 'api.wooblazz.com'}/o1/generate/qr'
    --header 'Accept: */*'
    --header 'Content-Type: application/json'
    --header 'Authorization: ${apiKey}'
    `


    // ===================== CHECK SESSION =====================
    const python_check_session = `
    import requests
    import json

    reqUrl = "https://${window.location.host === 'www.otpwa.com' || window.location.host === 'localhost:3011' ? 'api.otpwa.com' : 'api.wooblazz.com'}/o1/checksession"

    apiKey = "${apiKey}"

    headersList = {
    "Accept": "*/*",
    "Content-Type": "application/json", 
    "Authorization": apiKey
    }

    response = requests.request("GET", reqUrl, headers=headersList)

    print(response.text)
    `

    const javascript_check_session = `
    const axios = require('axios');

    const reqUrl = "https://${window.location.host === 'www.otpwa.com' || window.location.host === 'localhost:3011' ? 'api.otpwa.com' : 'api.wooblazz.com'}/o1/checksession";

    const headersList = {
    "Accept": "*/*",
    "Content-Type": "application/json",
    "Authorization": "${apiKey}" 
    };

    axios.get(reqUrl, { headers: headersList })
    .then((response) => {
        console.log(response.data);
    })
    .catch((error) => {
        console.error(error);
    });
    `

    const php_check_session = `
    <?php

    $reqUrl = "https://${window.location.host === 'www.otpwa.com' || window.location.host === 'localhost:3011' ? 'api.otpwa.com' : 'api.wooblazz.com'}/o1/checksession";

    $headersList = [
        "Accept: */*",
        "Content-Type: application/json",
        "Authorization: ${apiKey}"
    ];

    $ch = curl_init($reqUrl);
    curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);
    curl_setopt($ch, CURLOPT_HTTPHEADER, $headersList);

    $response = curl_exec($ch);

    if ($response === false) {
        echo "Error: " . curl_error($ch);
    } else {
        echo $response;
    }

    curl_close($ch);
    ?>
    `

    const bash_check_session = `
    curl  -X GET
    'https://${window.location.host === 'www.otpwa.com' || window.location.host === 'localhost:3011' ? 'api.otpwa.com' : 'api.wooblazz.com'}/o1/checksession'
    --header 'Accept: */*'
    --header 'Content-Type: application/json'
    --header 'Authorization: ${apiKey}'
    `


    // ===================== SET WEBHOOK INBOUND =====================
    const python_set_webhook = `
    import requests
    import json

    reqUrl = "https://${window.location.host === 'www.otpwa.com' || window.location.host === 'localhost:3011' ? 'api.otpwa.com' : 'api.wooblazz.com'}/o1/setwebhookinbound"

    apiKey = "${apiKey}"

    headersList = {
    "Accept": "*/*",
    "Content-Type": "application/json", 
    "Authorization": apiKey
    }

    payload = json.dumps({
    "endpoint_url": "<<endpoint_url>>",
    })
    
    response = requests.request("POST", reqUrl, headers=headersList)

    print(response.text)
    `

    const javascript_set_webhook = `
    const axios = require('axios');

    const reqUrl = "https://${window.location.host === 'www.otpwa.com' || window.location.host === 'localhost:3011' ? 'api.otpwa.com' : 'api.wooblazz.com'}/o1/setwebhookinbound";

    const headersList = {
    "Accept": "*/*",
    "Content-Type": "application/json",
    "Authorization": "${apiKey}" 
    };

    const payload = {
    endpoint_url: "<<endpoint_url>>",
    };

    axios.post(reqUrl, payload, { headers: headersList })
    .then((response) => {
        console.log(response.data);
    })
    .catch((error) => {
        console.error(error);
    });
    `

    const php_set_webhook = `
    <?php

    $reqUrl = "https://${window.location.host === 'www.otpwa.com' || window.location.host === 'localhost:3011' ? 'api.otpwa.com' : 'api.wooblazz.com'}/o1/setwebhookinbound";

    $headersList = [
        "Accept: */*",
        "Content-Type: application/json",
        "Authorization: ${apiKey}"
    ];

    $data = [
        "endpoint_url" => "<<endpoint_url>>",
    ];

    $ch = curl_init($reqUrl);
    curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);
    curl_setopt($ch, CURLOPT_HTTPHEADER, $headersList);
    curl_setopt($ch, CURLOPT_POSTFIELDS, json_encode($data));

    $response = curl_exec($ch);

    if ($response === false) {
        echo "Error: " . curl_error($ch);
    } else {
        echo $response;
    }

    curl_close($ch);
    ?>
    `

    const bash_set_webhook = `
    curl -X POST "https://${window.location.host === 'www.otpwa.com' || window.location.host === 'localhost:3011' ? 'api.otpwa.com' : 'api.wooblazz.com'}/o1/setwebhookinbound" 
    -H "Accept: */*" 
    -H "Content-Type: application/json" 
    -H "Authorization: ${apiKey}"
    -d '{
    "endpoint_url": "<<endpoint_url>>",
    }'
    `


    const generateAPIKey = async () => {
        try {
            const response = await axios.post(`https://${window.location.host === 'www.otpwa.com' || window.location.host === 'localhost:3011' ? 'api.otpwa.com' : 'api.wooblazz.com'}/api/v1/encrypt`, {
                key: base64encrypt(encryptRot13(atob(code!)))
            })

            if (response.data.success) {
                setApiKey(response.data.data)
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        generateAPIKey()

        if (window.location.host === 'www.otpwa.com' || window.location.host === 'localhost:3011') {
            document.title = "OTP WA"
            headDescription?.setAttribute("content", "OTP Whatsapp");
            headFavicon?.setAttribute("href", toAbsoluteUrl("/media/logos/logo-otpwa-white-2.png"));
            setLogo(toAbsoluteUrl("/media/logos/logo-otpwa-black.png"))
        } else {
            document.title = "Wooblazz"
            headDescription?.setAttribute("content", "WooBlazz Corporate");
            headFavicon?.setAttribute("href", toAbsoluteUrl("/media/logos/logo-wooblazz.png"));
            setLogo(toAbsoluteUrl("/media/logos/wooblazz.png"))
        }

        // Initialize scrollspy
        Events.scrollEvent.register('begin', function (to, element) {
            console.log('begin', arguments);
        });

        Events.scrollEvent.register('end', function (to, element) {
            console.log('end', arguments);
        });

        scrollSpy.update();

        return () => {
            Events.scrollEvent.remove('begin');
            Events.scrollEvent.remove('end');
        };
    }, [])

    return (
        <div style={{ overflowX: "hidden" }}>
            <div className="row" style={{ minHeight: "100vh", color: "black" }}>
                <div className="col-12 col-lg-0" id="api-navbar" style={{ backgroundColor: "whitesmoke" }}>
                    <nav className="navbar navbar-expand-lg bg-body-tertiary" style={{ position: "fixed", zIndex: "9999", backgroundColor: 'white' }}>
                        <div className="container-fluid">
                            <img src={logo} className="img-fluid w-25" alt="logo" style={{ margin: "20px 0px" }} />
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" >
                                <i className="fas fa-solid fa-bars fs-1"></i>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul style={{ listStyleType: "none" }} className="px-2 mt-10 fs-2">
                                    <li className="py-5 fw-bolder" style={{ cursor: "pointer" }}>
                                        <Link to="sendMessage" spy smooth={true} duration={500}>
                                            Send Message
                                        </Link>
                                    </li>
                                    <li className="py-5 fw-bolder" style={{ cursor: "pointer" }}>
                                        <Link to="checkNumber" spy smooth={true} duration={500}>
                                            Check Number
                                        </Link>
                                    </li>
                                    {
                                        (window.location.host !== 'www.otpwa.com' && window.location.host !== 'localhost:3011') &&
                                        <>
                                            <li className="py-5 fw-bolder" style={{ cursor: "pointer" }}>
                                                <Link to="mediaDownloader" spy smooth={true} duration={500}>
                                                    Media Downloader
                                                </Link>
                                            </li>
                                            <li className="py-5 fw-bolder" style={{ cursor: "pointer" }}>
                                                <Link to="retrieveMessage" spy smooth={true} duration={500}>
                                                    Retrieve Message
                                                </Link>
                                            </li>
                                            <li className="py-5 fw-bolder" style={{ cursor: "pointer" }}>
                                                <Link to="generateQr" spy smooth={true} duration={500}>
                                                    Generate QR Whatsapp
                                                </Link>
                                            </li>
                                            <li className="py-5 fw-bolder" style={{ cursor: "pointer" }}>
                                                <Link to="checkSession" spy smooth={true} duration={500}>
                                                    Check Session Whatsapp
                                                </Link>
                                            </li>
                                            <li className="py-5 fw-bolder" style={{ cursor: "pointer" }}>
                                                <Link to="setWebhook" spy smooth={true} duration={500}>
                                                    Set Webhook Inbound
                                                </Link>
                                            </li>
                                        </>
                                    }
                                </ul>
                            </div>
                        </div>
                    </nav>
                    <div className="px-7" style={{ backgroundColor: 'white', paddingTop: "8rem" }}>
                        <p>
                            <span style={headerInfoStyle}>
                                Version:&nbsp;
                            </span>
                            1.0.0
                        </p>
                        <p>
                            <span style={headerInfoStyle}>
                                Last Updated:&nbsp;
                            </span>
                            16 November 2023
                        </p>
                        <p>
                            <span style={headerInfoStyle}>
                                API Key:&nbsp;
                            </span>
                            <span style={{ fontSize: "0.95rem" }}>
                                {apiKey}
                            </span>
                        </p>
                        <p>
                            <span style={headerInfoStyle}>
                                User:&nbsp;
                            </span>
                            {agent?.user_name}
                        </p>
                        <p>
                            <span style={headerInfoStyle}>
                                WhatsApp:&nbsp;
                            </span>
                            {agent?.user_phone}
                        </p>
                    </div>
                </div>
                <div className="col-12 col-lg-3 p-10 pt-0" id="sidebar" style={{
                    backgroundColor: "#F4F5F8",
                    position: "fixed",
                    height: "100%",
                    overflowY: "auto",
                    display: "none",  // initially hide on smaller screens
                }}
                >
                    <img src={logo} className="img-fluid w-75" alt="logo" style={{ margin: "20px 0px" }} />
                    <h5>API Documentation</h5>

                    <div className="mt-10">
                        <p>
                            <span style={headerInfoStyle}>
                                Version:&nbsp;
                            </span>
                            1.0.0
                        </p>
                        <p>
                            <span style={headerInfoStyle}>
                                Last Updated:&nbsp;
                            </span>
                            16 November 2023
                        </p>
                        <p>
                            <span style={headerInfoStyle}>
                                API Key:&nbsp;
                            </span>
                            <span style={{ fontSize: "0.95rem" }}>
                                {apiKey}
                            </span>
                        </p>
                        <p>
                            <span style={headerInfoStyle}>
                                User:&nbsp;
                            </span>
                            {agent?.user_name}
                        </p>
                        <p>
                            <span style={headerInfoStyle}>
                                WhatsApp:&nbsp;
                            </span>
                            {agent?.user_phone}
                        </p>
                    </div>

                    <ul style={{ listStyleType: "none" }} className="p-0 mt-10 fs-2">
                        <li className="py-5" style={{ cursor: "pointer" }}>
                            <Link to="sendMessage" spy smooth={true} duration={500}>
                                Send Message
                            </Link>
                        </li>
                        <li className="py-5" style={{ cursor: "pointer" }}>
                            <Link to="checkNumber" spy smooth={true} duration={500}>
                                Check Number
                            </Link>
                        </li>
                        {
                            (window.location.host !== 'www.otpwa.com' && window.location.host !== 'localhost:3011') &&
                            <>
                                <li className="py-5" style={{ cursor: "pointer" }}>
                                    <Link to="mediaDownloader" spy smooth={true} duration={500}>
                                        Media Downloader
                                    </Link>
                                </li>
                                <li className="py-5" style={{ cursor: "pointer" }}>
                                    <Link to="retrieveMessage" spy smooth={true} duration={500}>
                                        Retrieve Message
                                    </Link>
                                </li>
                                <li className="py-5" style={{ cursor: "pointer" }}>
                                    <Link to="generateQr" spy smooth={true} duration={500}>
                                        Generate QR Whatsapp
                                    </Link>
                                </li>
                                <li className="py-5" style={{ cursor: "pointer" }}>
                                    <Link to="checkSession" spy smooth={true} duration={500}>
                                        Check Session Whatsapp
                                    </Link>
                                </li>
                                <li className="py-5" style={{ cursor: "pointer" }}>
                                    <Link to="setWebhook" spy smooth={true} duration={500}>
                                        Set Webhook Inbound
                                    </Link>
                                </li>
                            </>
                        }
                    </ul>
                </div>
                <div className="col-12 col-lg-9 offset-lg-3" id="documentation" style={{ marginLeft: "auto" }}>
                    <div className="row">
                        <div className="col-12 col-lg-6 p-10 pt-0">
                            <Element name="sendMessage">
                                <Documentation
                                    name="Send Message"
                                    endpoint={`https://${window.location.host === 'www.otpwa.com' || window.location.host === 'localhost:3011' ? 'api.otpwa.com' : 'api.wooblazz.com'}/o1/send`}
                                    description="Mengirim pesan ke nomor WhatsApp tertentu."
                                    method="POST"
                                    apiKey={apiKey}
                                    queryParameters={sendQueryParameters}
                                />
                            </Element>
                        </div>
                        <div className="col-12 col-lg-6 p-10 pt-0" style={{ backgroundColor: "#323F4C", color: "white", minHeight: "100vh" }}>
                            {
                                apiKey &&
                                <Code
                                    type={"send"}
                                    responseExample={sendExample}
                                    python={python_send}
                                    javascript={javascript_send}
                                    php={php_send}
                                    bash={bash_send}
                                />
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-6 p-10 pt-0">
                            <Element name="checkNumber">
                                <Documentation
                                    name="Check Number"
                                    endpoint={`https://${window.location.host === 'www.otpwa.com' || window.location.host === 'localhost:3011' ? 'api.otpwa.com' : 'api.wooblazz.com'}/o1/check_number`}
                                    description="Mengecek apakah nomor tertentu terdaftar di WhatsApp atau tidak."
                                    method="POST"
                                    apiKey={apiKey}
                                    queryParameters={checkQueryParameters}
                                />
                            </Element>
                        </div>
                        <div className="col-12 col-lg-6 p-10 pt-0" style={{ backgroundColor: "#323F4C", color: "white", minHeight: "100vh" }}>
                            {
                                apiKey &&
                                <Code
                                    type={"check"}
                                    responseExample={checkExample}
                                    python={python_check}
                                    javascript={javascript_check}
                                    php={php_check}
                                    bash={bash_check}
                                />
                            }
                        </div>
                    </div>
                    {
                        (window.location.host !== 'www.otpwa.com' && window.location.host !== 'localhost:3011') &&
                        <>
                            <div className="row">
                                <div className="col-12 col-lg-6 p-10 pt-0">
                                    <Element name="mediaDownloader">
                                        <Documentation
                                            name="Media Downloader"
                                            endpoint={`https://${window.location.host === 'www.otpwa.com' || window.location.host === 'localhost:3011' ? 'api.otpwa.com' : 'api.wooblazz.com'}/o1/download-media`}
                                            description="Download file media dari chat Whatsapp."
                                            method="POST"
                                            apiKey={apiKey}
                                            queryParameters={mediaQueryParameters}
                                        />
                                    </Element>
                                </div>
                                <div className="col-12 col-lg-6 p-10 pt-0" style={{ backgroundColor: "#323F4C", color: "white", minHeight: "100vh" }}>
                                    {
                                        apiKey &&
                                        <Code
                                            type={"media"}
                                            responseExample={mediaExample}
                                            python={python_media}
                                            javascript={javascript_media}
                                            php={php_media}
                                            bash={bash_media}
                                        />
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-lg-6 p-10 pt-0">
                                    <Element name="retrieveMessage">
                                        <Documentation
                                            name="Retrieve Message"
                                            endpoint="[endpoint user yang diberikan pada Wooblazz]"
                                            description="Wooblazz akan mengirimkan pesan pada endpoint terdaftar jika ada pesan masuk dari nomor WA pengguna"
                                            method="POST"
                                            apiKey={apiKey}
                                            queryParameters={retrieveQueryParameters}
                                        />
                                    </Element>
                                </div>
                                <div className="col-12 col-lg-6 p-10 pt-0" style={{ backgroundColor: "#323F4C", color: "white", minHeight: "100vh" }}>
                                    {
                                        apiKey &&
                                        <Code
                                            type={"retrieve"}
                                            python={python_retrieve}
                                            javascript={javascript_retrieve}
                                            php={php_retrieve}
                                            bash={bash_retrieve}
                                        />
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-lg-6 p-10 pt-0">
                                    <Element name="generateQr">
                                        <Documentation
                                            name="Generate QR Whatsapp"
                                            endpoint={`https://${window.location.host === 'www.otpwa.com' || window.location.host === 'localhost:3011' ? 'api.otpwa.com' : 'api.wooblazz.com'}/o1/generate/qr`}
                                            description="Membuat sesi QR Whatsapp."
                                            method="GET"
                                            apiKey={apiKey}
                                            queryParameters={[]}
                                        />
                                    </Element>
                                </div>
                                <div className="col-12 col-lg-6 p-10 pt-0" style={{ backgroundColor: "#323F4C", color: "white", minHeight: "100vh" }}>
                                    {
                                        apiKey &&
                                        <Code
                                            type={"generateQr"}
                                            responseExampleSuccess={generateQrExampleSuccess}
                                            responseExampleAlready={generateQrExampleAlready}
                                            python={python_generate_qr}
                                            javascript={javascript_generate_qr}
                                            php={php_generate_qr}
                                            bash={bash_generate_qr}
                                        />
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-lg-6 p-10 pt-0">
                                    <Element name="checkSession">
                                        <Documentation
                                            name="Check Whatsapp Session"
                                            endpoint={`https://${window.location.host === 'www.otpwa.com' || window.location.host === 'localhost:3011' ? 'api.otpwa.com' : 'api.wooblazz.com'}/o1/checksession`}
                                            description="Mengecek sesi Whatsapp."
                                            method="GET"
                                            apiKey={apiKey}
                                            queryParameters={[]}
                                        />
                                    </Element>
                                </div>
                                <div className="col-12 col-lg-6 p-10 pt-0" style={{ backgroundColor: "#323F4C", color: "white", minHeight: "100vh" }}>
                                    {
                                        apiKey &&
                                        <Code
                                            type={"checkSession"}
                                            responseExample={checkSessionExample}
                                            python={python_check_session}
                                            javascript={javascript_check_session}
                                            php={php_check_session}
                                            bash={bash_check_session}
                                        />
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-lg-6 p-10 pt-0">
                                    <Element name="setWebhook">
                                        <Documentation
                                            name="Set Webhook Inbound"
                                            endpoint={`https://${window.location.host === 'www.otpwa.com' || window.location.host === 'localhost:3011' ? 'api.otpwa.com' : 'api.wooblazz.com'}/o1/setwebhookinbound`}
                                            description="Mengatur endpoint untuk menerima chat yang masuk."
                                            method="POST"
                                            apiKey={apiKey}
                                            queryParameters={setWebhookQueryParameters}
                                        />
                                    </Element>
                                </div>
                                <div className="col-12 col-lg-6 p-10 pt-0" style={{ backgroundColor: "#323F4C", color: "white", minHeight: "100vh" }}>
                                    {
                                        apiKey &&
                                        <Code
                                            type={"setWebhook"}
                                            responseExample={setWebhookExample}
                                            python={python_set_webhook}
                                            javascript={javascript_set_webhook}
                                            php={php_set_webhook}
                                            bash={bash_set_webhook}
                                        />
                                    }
                                </div>
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export { OpenAPI }