import axios from "axios";

export const SHOW_QRIS = '/purchase'; // Use the relative endpoint
export const CHECK_QRIS = '/check';

const token = '63f2f1290e7a6';

export const showQris = async (receipt: string, amount: number, notes?: string) => {
    return axios.post(SHOW_QRIS,
        {
            receipt: receipt,
            amount: amount,
            ...(notes ? { notes: notes } : {})
        },
        {
            headers: {
                'x-auth-token': token
            },
        })
};

export const checkQris = async (receipt: string, amount: number) => {
    return axios.post(CHECK_QRIS,
        {
            receipt: receipt,
            amount: amount
        },
        {
            headers: {
                'x-auth-token': token
            },
        })
}