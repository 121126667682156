import { create } from 'zustand';

type Notification = {
    title: string;
    message: string;
}

interface notificationState {
    notification: Notification | null;
    setNotification: (notification: Notification | null) => void;
}

export const useNotificationStore = create<notificationState>((set) => ({
    notification: null,
    setNotification: (notification) => {
        set({ notification });

        if (notification) {
            setTimeout(() => {
                set({ notification: null });
            }, 5000);  // Remove notification after 5 seconds
        }
    },
}));
