import { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { formatDate, customNumberFormat } from '../../../function/general'
import moment from 'moment'
import 'moment/locale/id';
import { getUserByToken } from '../../modules/auth/redux/AuthCRUD'
import { shallowEqual, useSelector, connect, useDispatch, ConnectedProps } from 'react-redux'
import * as auth from '../../modules/auth/redux/AuthRedux'
import { RootState } from '../../../setup'
import Skeleton from 'react-loading-skeleton'
import { UserModel } from '../../modules/auth/models/UserModel'
import { useCorporateUserStore } from '../../../stores/CorporateUserStore';

const mapState = (state: RootState) => ({ auth: state.auth })
const connector = connect(mapState, auth.actions)
type PropsFromRedux = ConnectedProps<typeof connector>

const DashboardPage: FC<PropsFromRedux> = (props) => {

  const accessToken = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual)
  const dispatch = useDispatch()
  const reduxUser: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
  const { setCorporateUser } = useCorporateUserStore()

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    const fetchCorporateUser = async () => {
      try {
        const { data: user } = await getUserByToken(accessToken as string)
        setCorporateUser(user)
        dispatch(props.fulfillUser(user))
        setIsLoading(false)
      } catch (error) {
        console.error(error)
      }
    }

    fetchCorporateUser()
  }, [])

  let latest_expired = ''
  let credit_limit, percentage = 0

  if (reduxUser.data.credit_list.length > 0) {
    latest_expired = reduxUser.data.credit_list.reduce((prev, current) => (prev.date_expiration < current.date_expiration) ? prev : current).date_expiration
    credit_limit = reduxUser.data.credit_list.reduce((total, credit) => total + credit.credit_full, 0)
    percentage = (credit_limit - reduxUser.data.credit) / credit_limit * 100
  }
  return (
    <>
      <div className="text-gray-900 fs-2x fw-bold d-flex justify-content-between">
        <span>Halo, {
          isLoading ? <Skeleton width={100} /> :
            reduxUser.data.user_name
        }</span>
        {
          !reduxUser.data.credit_list.length &&
          <Link to={`/purchase`} className="btn btn-google">Beli Paket</Link>
        }
      </div>
      <ol className="breadcrumb text-muted fs-6 fw-bold flex-grow-0">
        <li className="breadcrumb-item text-dark">Beranda</li>
      </ol>
      <div className="card card-flush mt-5">
        <div className="card-header pt-5 d-block">
          <div className="card-title d-flex flex-column">
            <div className="d-flex align-items-center justify-content-between w-100">
              <span className="card-title fw-bolder text-gray-700 fs-2 mb-1 d-block">Token Korporat</span>
              <i className="fas fa-sharp fa-solid fa-paper-plane fs-1"></i>
            </div>
            <div>
              {
                isLoading ? <Skeleton width={100} /> :
                  (
                    <>
                      {
                        latest_expired ?
                          <span className="text-danger">* <span className="text-gray-500">Exp. {moment(latest_expired).format('DD MMM YYYY')}</span></span>
                          :
                          <span className="text-muted">Belum memiliki paket</span>
                      }
                    </>
                  )
              }
            </div>
          </div>
        </div>
        <div className="card-body d-flex align-items-end pt-0">
          {
            reduxUser.data.credit_list.length > 0 &&
            <div className="d-flex align-items-center flex-column mt-3 w-100">
              <div className="align-self-start">
                <span className="text-gray-800 fs-2qx fw-bolder me-2">
                  {
                    isLoading ?
                      (
                        <>
                          <Skeleton width={60} inline />
                          &nbsp;
                        </>
                      )
                      :
                      <>
                        <span className="text-gray-800 fs-2qx fw-bolder me-2 txtCredit2">
                          <p className="fw-bold text-muted fs-3">Sisa</p>
                          {
                            credit_limit &&
                            customNumberFormat(credit_limit - reduxUser.data.credit)
                          }
                        </span>
                        <span className="fs-3 text-muted">Token</span>
                      </>
                  }
                </span>
              </div>
              <span className="text-muted d-flex justify-content-end w-100">Total</span>
              <div className="d-flex justify-content-end mt-1 w-100">
                <span className="fw-bolder fs-6 pe-1 percentageCredit2">
                  {
                    credit_limit &&
                    customNumberFormat(credit_limit)
                  }
                </span>
                <span className="text-muted fs-6">Token</span>
              </div>
              <div className="h-8px mx-3 w-100 bg-light-warning rounded">
                <div className="bg-warning rounded h-8px" role="progressbar" style={{ width: `${isLoading ? 0 : percentage}%` }}></div>
              </div>
            </div>
          }
        </div>
        {
          reduxUser.data.credit_list.length > 0 &&
          <div className="m-0 p-10 pt-0 divDetailPackage1">
            <div className={`d-flex align-items-center collapsible pt-3 toggle mb-0 ${isLoading && 'd-none'} collapsed cursor-pointer justify-content-between`} data-bs-toggle="collapse" data-bs-target=".detailPackage1" aria-expanded="false">
              <div className="d-flex align-items-center flex-wrap">
                <span className="text-gray-800 fw-semibold me-3 mb-0 fw-bolder">
                  Detail Paket
                </span>
              </div>
              <div className="ms-n1">
                <span className="svg-icon toggle-on svg-icon-primary svg-icon-2">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="currentColor"></path>
                  </svg>
                </span>
                <span className="svg-icon toggle-off svg-icon-2">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.6343 12.5657L8.45001 16.75C8.0358 17.1642 8.0358 17.8358 8.45001 18.25C8.86423 18.6642 9.5358 18.6642 9.95001 18.25L15.4929 12.7071C15.8834 12.3166 15.8834 11.6834 15.4929 11.2929L9.95001 5.75C9.5358 5.33579 8.86423 5.33579 8.45001 5.75C8.0358 6.16421 8.0358 6.83579 8.45001 7.25L12.6343 11.4343C12.9467 11.7467 12.9467 12.2533 12.6343 12.5657Z" fill="currentColor"></path>
                  </svg>
                </span>
              </div>
            </div>
            <div className="fs-6 collapse pt-3 detailPackage1 text-muted">
              {
                reduxUser.data.credit_list.length > 0 &&
                reduxUser.data.credit_list.map((creditPackage, index) => {
                  return (
                    <ul key={index} className="custom-bullet">
                      <li key={index} className="d-flex justify-content-between">
                        <span><span className="fw-bolder">{creditPackage.credit} /</span> {creditPackage.credit_full} Pesan</span>
                        <span className="text-muted">Exp. {formatDate(creditPackage.date_expiration)}</span>
                      </li>
                    </ul>
                  )
                })
              }
            </div>
          </div>
        }
      </div>
    </>
  )
}

export default connector(DashboardPage)