/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { UserModel } from '../../../../app/modules/auth/models/UserModel'
import { RootState } from '../../../../setup'
import * as auth from '../../../../app/modules/auth/redux/AuthRedux'
import { useDispatch } from 'react-redux'
import Swal from 'sweetalert2'
import { KTSVG } from '../../../helpers'
import moment from 'moment'

const HeaderUserMenu: FC = () => {
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
  const dispatch = useDispatch()
  const logout = () => {
    Swal.fire({
      title: "Keluar",
      text: "Apakah Anda yakin ingin keluar?",
      icon: "warning",
      showCancelButton: !0,
      buttonsStyling: !1,
      confirmButtonText: "Ya, keluar!",
      cancelButtonText: "Tidak",
      customClass: {
        confirmButton: "btn btn-danger",
        cancelButton: "btn btn-active-light",
      },
      reverseButtons: true,
      heightAuto: false,
      preConfirm: () => {
        dispatch(auth.actions.logout())
        localStorage.removeItem('corporate-user-storage')
        localStorage.removeItem('users-storage')
      }
    })
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {user.data.user_name}
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary'>
              {user.data.user_username}
            </a>
            <span className='text-muted'>
              <span className='text-danger'>*</span>Exp. {moment(user.data.user_expired).format("DD MMMM YYYY")}
            </span>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <span role="button" data-bs-toggle="modal"
          data-bs-target="#modal-change-password" className="menu-link px-5">
          <span className="fas fa-pencil-alt fs-4 menu-icon"></span>
          <span className="menu-title">Ubah Password</span>
        </span>
      </div>

      <div className='menu-item px-5'>
        <a onClick={logout} className="menu-link px-5" id="btnLogout">
          <span className="fas fa-sign-out-alt fs-4 menu-icon"></span>
          <span className="menu-title">Keluar</span>
        </a>
      </div>
    </div>
  )
}

export { HeaderUserMenu }
