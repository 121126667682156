import { create } from 'zustand'
import { UserModel } from '../app/modules/auth/models/UserModel';
import { persist, createJSONStorage } from 'zustand/middleware';

type CorporateUserStoreState = {
    corporateUser: UserModel;
    setCorporateUser: (corporateUser: UserModel) => void;
};

export const useCorporateUserStore = create<CorporateUserStoreState>()(
    persist(
        (set) => ({
            corporateUser: {} as UserModel,
            setCorporateUser: (corporateUser) => set({ corporateUser }),
        }),
        ({
            name: 'corporate-user-storage', // name of the item in the storage (must be unique)
            storage: createJSONStorage(() => localStorage),
            skipHydration: true
        })
    )
)
