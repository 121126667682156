import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL

export const GET_PURCHASE_HISTORY = `${API_URL}wzcorp/transaction_history`

export function getPurchaseHistory(accessToken: string) {
    return axios.post(GET_PURCHASE_HISTORY, {},
        {
            headers: {
                'x-auth-token': accessToken
            }
        }
    );
}

