import { create } from 'zustand';
import { Log } from '../interfaces/Log';

interface LogState {
    logs: Log[];
    setLogs: (logs: Log[]) => void;
}

export const useLogStore = create<LogState>((set, get) => ({
    logs: [],
    setLogs: (logs) => set({ logs }),
}));