import { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { AsideDefault } from './components/aside/AsideDefault'
import { Footer } from './components/Footer'
import { HeaderWrapper } from './components/header/HeaderWrapper'
import { ScrollTop } from './components/ScrollTop'
import { Content } from './components/Content'
import { PageDataProvider } from './core'
import { useLocation } from 'react-router-dom'
import { DrawerMessenger, ActivityDrawer, Main, InviteUsers, UpgradePlan } from '../partials'
import { MenuComponent } from '../assets/ts/components'
import { ChangePassword } from '../../components/ChangePassword'
import CompleteProfileModal from '../../components/CompleteProfileModal'
import { UserModel } from '../../app/modules/auth/models/UserModel'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../setup'
import moment from 'moment'
import { ExpiredModal } from '../../components/ExpiredModal'
import { KTSVG, toAbsoluteUrl } from '../../_metronic/helpers'

const MasterLayout = () => {
  const location = useLocation()
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
  const [remainDate, setRemainDate] = useState(0)

  const headDescription = document.querySelector("meta[name='description']");
  const headFavicon = document.querySelector("link[rel='shortcut icon']");
  const logoSide = document.querySelector("#logo-side");
  console.log(window.location.host)
  if (window.location.host === 'www.otpwa.com' || window.location.host === 'localhost:3011') {
    document.title = "OTP WA"
    headDescription?.setAttribute("content", "OTP Whatsapp");
    headFavicon?.setAttribute("href", toAbsoluteUrl("/media/logos/logo-otpwa-white-2.png"));
    logoSide?.setAttribute("src", toAbsoluteUrl("/media/logos/logo-otpwa-white.png"));
  } else {
    document.title = "Wooblazz"
    headDescription?.setAttribute("content", "WooBlazz Corporate");
    headFavicon?.setAttribute("href", toAbsoluteUrl("/media/logos/logo-wooblazz.png"));
    logoSide?.setAttribute("src", toAbsoluteUrl("/media/logos/wooblazz.png"));
  }

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)

    var dateExpired = moment(user.data.user_expired)
    var dateNow = moment()
    var daysRemaining = dateExpired.diff(dateNow, 'days')
    setRemainDate(daysRemaining)
    console.log(daysRemaining)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  console.log(user)

  return (
    <PageDataProvider>
      <div className='page d-flex flex-row flex-column-fluid'>
        <AsideDefault />
        <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper' style={{ paddingTop: "65px" }}>
          <HeaderWrapper />

          <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
            {/* <Toolbar /> */}
            <div className='post d-flex flex-column-fluid' id='kt_post'>
              <ChangePassword />
              {
                remainDate <= 0 && <ExpiredModal />
              }
              <Content>
                {
                  remainDate <= 7 &&
                  <div className="alert alert-danger">
                    <div className="d-flex flex-column">
                      <span>Masa aktif akun kamu tinggal <b>{remainDate} hari</b></span>
                    </div>
                  </div>
                }
                <Outlet />
              </Content>
            </div>
          </div>
          <Footer />
        </div>
      </div>

      <CompleteProfileModal />

      {/* begin:: Drawers */}
      <ActivityDrawer />
      {/* <RightToolbar /> */}
      <DrawerMessenger />
      {/* end:: Drawers */}

      {/* begin:: Modals */}
      <Main />
      <InviteUsers />
      <UpgradePlan />
      {/* end:: Modals */}
      <ScrollTop />

    </PageDataProvider>
  )
}

export { MasterLayout }
