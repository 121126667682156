import { create } from 'zustand';

export interface PurchaseHistory {
    code: number;
    date: string;
    date_formatted: string;
    hash: string;
    number: string;
    price: number;
    qr: string;
    status: number;
    type: number;
    value: number;
}

interface PurchaseHistoryState {
    purchasesHistory: PurchaseHistory[];
    setPurchaseHistory: (purchasesHistory: PurchaseHistory[]) => void;
}

export const usePurchaseHistoryStore = create<PurchaseHistoryState>((set, get) => ({
    purchasesHistory: [],
    setPurchaseHistory: (purchasesHistory) => set({ purchasesHistory }),
}));