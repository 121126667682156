import { useState, useEffect } from "react";
import { History } from '../../stores/UserBroadcastStore';
import { getDetailByUser } from "../../app/modules/users/broadcast-history/UserBroadcastCRUD";
import { useUserBroadcastStore } from "../../stores/UserBroadcastStore";
import moment from "moment";
import 'moment/locale/id';
import { exportExcel } from "../../function/exportExcel";
import { formatDateDataTable } from "../../function/general";
import Skeleton from 'react-loading-skeleton'
import 'rsuite/dist/rsuite.min.css';
import DataTable, { TableColumn } from 'react-data-table-component';
import { User } from "../../interfaces/User";
import { Modal } from "bootstrap";
import { Log } from "./Log";
interface ChatHistoryProps {
    user: User
    startDate: Date
    endDate: Date
}

const ChatHistory: React.FC<ChatHistoryProps> = ({ user, startDate, endDate }) => {

    const [showChat, setShowChat] = useState<boolean>(false);
    const [chatHistory, setChatHistory] = useState<History>();

    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [selectedValue, setSelectedValue] = useState('Status');
    const [filterText, setFilterText] = useState<string>('');
    const { histories, setHistories } = useUserBroadcastStore();
    const [loading, setLoading] = useState<boolean>(false);

    const filteredData = histories
        .filter(item => {
            // If there's a filterText, filter based on it.
            if (filterText) {
                return Object.values(item).some(val =>
                    String(val).toLowerCase().includes(filterText.toLowerCase())
                );
            }
            // If there's no filterText, return all items.
            return true;
        })
        .filter(item => {
            if (selectedValue === "Berhasil") return Number(item.status) === 1;
            if (selectedValue === "Gagal") return Number(item.status) !== 1;
            return true
        });


    const columns: TableColumn<History>[] = [
        {
            name: 'No',
            minWidth: '60px',
            cell: (row, rowIndex) => {
                const actualIndex = (currentPage - 1) * rowsPerPage + rowIndex + 1;
                return <span>{actualIndex}</span>;
            },
            center: true,
            sortable: true,
        },
        {
            name: 'Nama',
            selector: row => row.name,
            width: '200px',
            sortable: true,
        },
        {
            name: 'Nomor Whatsapp',
            selector: row => row.phone,
            width: '150px',
            sortable: true,
        },
        {
            name: 'Konten',
            selector: row => row.message,
            width: '200px',
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => row.status,
            width: '170px',
            cell(row) {
                switch (row.status) {
                    case 1:
                        return <span className="badge badge-light-success">Berhasil</span>
                    case 2:
                        return <span className="badge badge-light-warning">Pending</span>
                    case 3:
                        return <span className="badge badge-light-danger">Gagal</span>
                    case 4:
                        return <span className="badge badge-light-danger">Nomor tidak valid</span>
                    case 5:
                        return <span className="badge badge-light-danger">Melebihi kuota</span>
                    case 6:
                        return <span className="badge badge-light-danger">Melebihi limit harian</span>
                    case 7:
                        return <span className="badge badge-light-danger">Tidak terhubung dgn WA</span>
                    case 8:
                        return <span className="badge badge-light-danger">User berhenti berlangganan</span>
                }
            },
            sortable: true,
        },
        {
            name: 'Tanggal',
            selector: row => row.date_sent,
            width: '200px',
            sortable: true,
        },
        {
            name: 'Dibalas',
            selector: row => row.response_time,
            cell(row) {
                return row.response_time > 0
                    ? <span className="badge badge-light-success">Ya</span>
                    : <span className="badge badge-light-danger">Tidak</span>
            },
            sortable: true,
        },
        {
            name: 'Kecepatan Balas',
            selector: row => row.response_time,
            width: '150px',
            cell(row) {
                if (row.response_time <= 0) return '-';

                const duration = moment.duration(row.response_time, 'seconds');
                const hours = Math.floor(duration.asHours());
                const minutes = duration.minutes();
                const seconds = duration.seconds();

                let result = "";

                if (hours > 0) {
                    result += `${hours} Jam `;
                }

                // Only add minutes if it's non-zero
                if (minutes > 0) {
                    result += `${minutes} Menit `;
                }

                // Add seconds regardless of hours or minutes, but only if it's non-zero
                if (seconds > 0) {
                    result += `${seconds} Detik`;
                }

                // Trim any extra spaces and return
                return result.trim();

            },
            sortable: true,
        },
        {
            name: 'Tipe',
            selector: row => row.broadcast_code,
            cell(row) {
                return row.broadcast_code ? <span className="badge badge-light-info">Broadcast</span> : <span className="badge badge-light-warning">API</span>;
            },
            sortable: true,
        },
    ]

    useEffect(() => {
        if (!loading) setLoading(true);
        const fetchHistories = async () => {
            try {
                const response = await getDetailByUser(user.user_phone, formatDateDataTable(startDate), formatDateDataTable(endDate));
                if (response.data.success) {
                    setTimeout(() => {
                        response.data.data.sort((a: History, b: History) => {
                            return new Date(a.date_sent).getTime() - new Date(b.date_sent).getTime();
                        });
                        response.data.data.forEach((history: History) => {
                            // Replace standalone % with its URI-encoded value %25
                            history.message = history.message.replace(/%(?![0-9a-fA-F]{2})/g, '%25');

                            // Decode URI components
                            history.message = decodeURIComponent(history.message);

                            // Sort date sent ascending
                            history.date_sent = moment(history.date_sent).format('DD MMMM YYYY HH:mm');
                        });
                        console.log(response.data.data)
                        setHistories(response.data.data);
                        setLoading(false);
                    }, 1000);
                } else {
                    throw new Error("Failed to fetch users");
                }
            } catch (error) {
                throw error;
            }
        }
        fetchHistories();
    }, [startDate, endDate]);

    useEffect(() => {
        if (showChat && chatHistory) {
            const modalElement = document.getElementById(`chat-history-log-${chatHistory.code}`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowChat(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showChat, chatHistory, setShowChat]);

    return (
        <div className="tab-pane fade show active" id="history_tab_pane" role="tabpanel">
            <div className="card rounded">
                <div className="row g-5 g-xl-8 p-5 pb-0">
                    <div className="col-md-3">
                        <div className="card bg-primary card-xl-stretch mb-xl-8">
                            <div className="card-body">
                                <i className="fas fa-users text-gray-100 fs-1"></i>
                                <div className="text-gray-100 fw-bolder fs-2 mb-2 mt-5 w-75">
                                    <span id="txtMessageFull" className="d-flex w-100">
                                        <span className="skeleton skeleton-text w-100 h-20px">
                                            {
                                                loading ?
                                                    <Skeleton />
                                                    :
                                                    histories.length
                                            }
                                        </span>
                                    </span>
                                </div>
                                <div className="fw-bold text-gray-100">Jumlah Penerima</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="card bg-success card-xl-stretch mb-xl-8">
                            <div className="card-body">
                                <i className="fas fa-comment text-gray-100 fs-1"></i>
                                <div className="text-gray-100 fw-bolder fs-2 mb-2 mt-5 w-75">
                                    <span id="txtMessageSent" className="d-flex w-100">
                                        <span className="skeleton skeleton-text w-100 h-20px">
                                            {
                                                loading ?
                                                    <Skeleton />
                                                    :
                                                    histories.filter((history: History) => Number(history.status) === 1).length
                                            }
                                        </span>
                                    </span>
                                </div>
                                <div className="fw-bold text-gray-100">Berhasil Terkirim
                                    <span id="txtPercentageSent">
                                        {
                                            !loading &&
                                            ` (${histories.length > 0 ? (histories.filter((history: History) => Number(history.status) === 1).length / histories.length * 100).toFixed(0) : 0}%)`
                                        }
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="card bg-danger card-xl-stretch mb-5 mb-xl-8" id="failedCard">
                            <div className="card-body">
                                <i className="fas fa-comment-slash fs-1 text-gray-100"></i>
                                <div className="text-gray-100 fw-bolder fs-2 mb-2 mt-5 w-75">
                                    <span id="txtMessageFail" className="d-flex w-100">
                                        <span className="skeleton skeleton-text w-100 h-20px">
                                            {
                                                loading ?
                                                    <Skeleton />
                                                    :
                                                    histories.filter((history: History) =>
                                                        Number(history.status) !== 1
                                                    ).length
                                            }
                                        </span>
                                    </span>
                                </div>
                                <div className="fw-bold text-gray-100">Gagal Terkirim
                                    <span id="txtPercentageFail">
                                        {
                                            !loading &&
                                            ` (${histories.length > 0 ?
                                                (histories.filter((history: History) =>
                                                    Number(history.status) >= 2 && Number(history.status) <= 8).length
                                                    / histories.length * 100).toFixed(0)
                                                : 0}%)`
                                        }
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="card bg-info card-xl-stretch mb-5 mb-xl-8" id="failedCard">
                            <div className="card-body">
                                <i className="fas fa-reply fs-1 text-gray-100"></i>
                                <div className="text-gray-100 fw-bolder fs-2 mb-2 mt-5 w-75">
                                    <span id="txtMessageFail" className="d-flex w-100">
                                        <span className="skeleton skeleton-text w-100 h-20px">
                                            {
                                                loading ?
                                                    <Skeleton />
                                                    :
                                                    `${histories.filter((history: History) =>
                                                        history.response_time > 0
                                                    ).length} / ${histories.length}`
                                            }
                                        </span>
                                    </span>
                                </div>
                                <div className="fw-bold text-gray-100">Persentase Dibalas
                                    <span id="txtPercentageFail">
                                        {
                                            !loading &&
                                            ` (${histories.length > 0 ?
                                                (histories.filter((history: History) =>
                                                    history.response_time > 0).length
                                                    / histories.length * 100).toFixed(0)
                                                : 0}%)`

                                        }
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    loading &&
                    <div className="loading-overlay">
                        <div className="loader"></div>
                    </div>
                }
                <div className="card-header border-0 pt-6">
                    <div className="card-title">
                        <div className="d-flex align-items-center position-relative my-1 skeleton-button" id="divSearch">
                            <span className="svg-icon svg-icon-1 position-absolute ms-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="black" />
                                    <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="black" />
                                </svg>
                            </span>
                            <input
                                type="text"
                                data-kt-lists-table-filter="search"
                                className="form-control form-control-solid w-250px ps-15 skeleton"
                                placeholder="Cari"
                                id="inputSearch"
                                value={filterText}
                                onChange={e => setFilterText(e.target.value)}
                                disabled={loading || histories.length === 0}
                            />
                        </div>
                    </div>
                    <div className="card-toolbar">
                        <div className="d-flex justify-content-end" data-kt-lists-table-toolbar="base">
                            <button
                                type="button"
                                className="btn btn-light-success me-3 skeleton skeleton-button"
                                id="optionImport"
                                onClick={() => exportExcel(filteredData, filterText, startDate, endDate, user, selectedValue)}
                                disabled={loading || histories.length === 0}
                            >
                                <i className="fas fa-file-upload fs-2 me-2"></i>Ekspor Excel
                            </button>
                        </div>
                        <div className="mw-175px">
                            <select
                                value={selectedValue}
                                onChange={(e) => setSelectedValue(e.target.value)}
                                className="form-select form-select-solid"
                                id="filterSelect"
                            >
                                <option value="Status" disabled>Status</option>
                                <option value="">Semua status</option>
                                <option value="Berhasil">Berhasil</option>
                                <option value="Gagal">Gagal</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="card-body pt-0 logs">
                    {
                        showChat && <Log history={chatHistory!} />
                    }
                    <DataTable
                        columns={columns}
                        data={filteredData}
                        pagination
                        onChangePage={(page) => setCurrentPage(page)}
                        onChangeRowsPerPage={(currentRowsPerPage: number) => setRowsPerPage(currentRowsPerPage)}
                        onRowClicked={(row) => {
                            setShowChat(true);
                            setChatHistory(row);
                        }}
                        className="logs-table"
                    />
                </div>
            </div>
        </div>
    )
}

export { ChatHistory }