import { useState, useEffect } from 'react';
import { getUsers } from '../../modules/users/UserCRUD';
import { RootState } from '../../../setup';
import { useSelector, shallowEqual } from 'react-redux'
import { UserItem } from '../../../components/User/UserItem';
import { toAbsoluteUrl } from '../../../_metronic/helpers';
import { Connect } from '../../../components/User/Connect';
import { useUserStore } from '../../../stores/UserStore';
import { useNotificationStore } from '../../../stores/NotificationStore';
import Skeleton from 'react-loading-skeleton';
import { User } from '../../../interfaces/User';

const Users: React.FC = () => {
    const accessToken = useSelector<RootState, string>(
        ({ auth }) => auth.accessToken as string,
        shallowEqual
    );

    const [loading, setLoading] = useState<boolean>(false);
    const { users, setUsers } = useUserStore()
    const { notification } = useNotificationStore()
    const [filterText, setFilterText] = useState('');
    const [groupedUsers, setGroupedUsers] = useState([]);

    useEffect(() => {
        setLoading(true);

        const fetchUsers = async () => {
            try {
                const response = await getUsers(accessToken);

                if (response.data.success) {
                    setUsers(response.data.data);
                } else {
                    throw new Error("Failed to fetch users");
                }
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        };

        fetchUsers();
    }, []);

    useEffect(() => {
        if (users) {
            const filteredUsers = users.flat().filter((item: any) => {
                // Check filterText
                if (filterText) {
                    const filterWords = filterText.split(' ');

                    return Object.values(item).some(val => {
                        return filterWords.every(word => {
                            const regexText = '.*' + word.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&') + '.*';
                            const regex = new RegExp(regexText, 'i');
                            return regex.test(String(val));
                        });
                    });
                }

                return true; // If filterText is empty, include all items
            });

            // Membagi filteredUsers menjadi kelompok dua
            const groupedFilteredUsers: any = [];
            for (let i = 0; i < filteredUsers.length; i += 2) {
                groupedFilteredUsers.push(filteredUsers.slice(i, i + 2));
            }

            // Menetapkan hasil kelompok ke dalam state groupedUsers
            setGroupedUsers(groupedFilteredUsers);
        }
    }, [filterText, users]);


    return (
        <div>
            <ol className="breadcrumb text-muted fs-6 fw-bold mb-5">
                <li className="breadcrumb-item text-dark">Agen</li>
            </ol>

            <div className="row d-sm-flex justify-content-between">
                <div className="col-xl-5 col-lg-5 col-sm-5 mb-5">
                    <div className="d-flex flex-wrap align-items-center">
                        <div className="d-flex align-items-center position-relative">
                            <span className="svg-icon svg-icon-3 position-absolute ms-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="black"></rect>
                                    <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="black"></path>
                                </svg>
                            </span>
                            <input
                                type="text"
                                id="kt_filter_search"
                                className="form-control border-body bg-body w-210px ps-10"
                                placeholder="Cari"
                                onChange={e => setFilterText(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-xl-7 col-lg-7 col-sm-7 mb-5">
                    <div style={{ float: 'right' }}>
                        <Connect disabled={loading} connectedAgents={users.length} />
                    </div>
                </div>
            </div>
            {
                notification &&
                <div className="alert alert-success d-flex align-items-center p-3 my-5">
                    <img src={toAbsoluteUrl("/media/icons/duotune/general/gen026.svg")} alt="Icon" className="mx-5" height={50} />
                    <div className="d-flex flex-column">
                        <h5 className="mb-1">{notification.title}</h5>
                        <span>{notification.message}</span>
                    </div>
                </div>
            }
            <div>
                {
                    groupedUsers.length > 0 ?
                        <>
                            {
                                loading ?
                                    (
                                        <div className="card card-custom card-flush">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-12 col-lg-6 p-3">
                                                        {
                                                            loading ?
                                                                <Skeleton height={210} />
                                                                :
                                                                <div className="card card-custom border border-primary border-2 py-5">
                                                                    <div className="card-header">
                                                                        <div>
                                                                            {/* Skeleton for user name */}
                                                                            <h3 className="card-title fs-2 text-primary detail">
                                                                                <strong><Skeleton width={150} /></strong>
                                                                            </h3>
                                                                            {/* Skeleton for user phone */}
                                                                            <p><Skeleton width={100} /></p>
                                                                        </div>
                                                                        <div className="card-toolbar">
                                                                            {/* Skeleton for SVG icon */}
                                                                            <div>
                                                                                <div className="dropdown">
                                                                                    <span
                                                                                        className="d-inline-flex align-items-center"
                                                                                        style={{ cursor: 'pointer' }}
                                                                                        data-bs-toggle="dropdown"
                                                                                        aria-expanded="false"
                                                                                    >
                                                                                        <Skeleton width={30} height={30} />
                                                                                    </span>
                                                                                    {/* Skeleton for dropdown */}
                                                                                </div>
                                                                            </div>
                                                                            {/* Skeleton for connected text */}
                                                                            <p className="text-white"><Skeleton width={100} /></p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body d-flex align-items-center justify-content-between">
                                                                        <div>
                                                                            {/* Skeleton for user credit */}
                                                                            <p className="fs-4 m-0">Credits : <Skeleton width={50} /></p>
                                                                            {/* Skeleton for connected at */}
                                                                            <p className="fs-5 m-0">Connected at : <Skeleton width={100} /></p>
                                                                        </div>
                                                                        {/* Skeleton for Disconnect or SubmitOTP */}
                                                                    </div>
                                                                </div>
                                                        }
                                                    </div>
                                                    <div className="col-12 col-lg-6 p-3">
                                                        {
                                                            loading ?
                                                                <Skeleton height={210} />
                                                                :
                                                                <div className="card card-custom border border-primary border-2 py-5">
                                                                    <div className="card-header">
                                                                        <div>
                                                                            {/* Skeleton for user name */}
                                                                            <h3 className="card-title fs-2 text-primary detail">
                                                                                <strong><Skeleton width={150} /></strong>
                                                                            </h3>
                                                                            {/* Skeleton for user phone */}
                                                                            <p><Skeleton width={100} /></p>
                                                                        </div>
                                                                        <div className="card-toolbar">
                                                                            {/* Skeleton for SVG icon */}
                                                                            <div>
                                                                                <div className="dropdown">
                                                                                    <span
                                                                                        className="d-inline-flex align-items-center"
                                                                                        style={{ cursor: 'pointer' }}
                                                                                        data-bs-toggle="dropdown"
                                                                                        aria-expanded="false"
                                                                                    >
                                                                                        <Skeleton width={30} height={30} />
                                                                                    </span>
                                                                                    {/* Skeleton for dropdown */}
                                                                                </div>
                                                                            </div>
                                                                            {/* Skeleton for connected text */}
                                                                            <p className="text-white"><Skeleton width={100} /></p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body d-flex align-items-center justify-content-between">
                                                                        <div>
                                                                            {/* Skeleton for user credit */}
                                                                            <p className="fs-4 m-0">Credits : <Skeleton width={50} /></p>
                                                                            {/* Skeleton for connected at */}
                                                                            <p className="fs-5 m-0">Connected at : <Skeleton width={100} /></p>
                                                                        </div>
                                                                        {/* Skeleton for Disconnect or SubmitOTP */}
                                                                    </div>
                                                                </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                    :
                                    <div className="card card-custom card-flush">
                                        <div className="card-body">
                                            {

                                                groupedUsers.map((userGroup: any, groupIndex: number) => (
                                                    <div className="row" key={groupIndex}>
                                                        {userGroup.map((user: any, index: number) => (
                                                            <div className="col-12 col-lg-6 p-3" key={index}>
                                                                <UserItem key={user.corporate_user_code} user={user} />
                                                            </div>
                                                        ))}
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                            }
                        </>
                        :
                        <>
                            {
                                loading ?
                                    (
                                        <div className="card card-custom card-flush">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-12 col-lg-6 p-3">
                                                        {
                                                            loading ?
                                                                <Skeleton height={175} />
                                                                :
                                                                <div className="card card-custom border border-primary border-2 py-5">
                                                                    <div className="card-header">
                                                                        <div>
                                                                            {/* Skeleton for user name */}
                                                                            <h3 className="card-title fs-2 text-primary detail">
                                                                                <strong><Skeleton width={150} /></strong>
                                                                            </h3>
                                                                            {/* Skeleton for user phone */}
                                                                            <p><Skeleton width={100} /></p>
                                                                        </div>
                                                                        <div className="card-toolbar">
                                                                            {/* Skeleton for SVG icon */}
                                                                            <div>
                                                                                <div className="dropdown">
                                                                                    <span
                                                                                        className="d-inline-flex align-items-center"
                                                                                        style={{ cursor: 'pointer' }}
                                                                                        data-bs-toggle="dropdown"
                                                                                        aria-expanded="false"
                                                                                    >
                                                                                        <Skeleton width={30} height={30} />
                                                                                    </span>
                                                                                    {/* Skeleton for dropdown */}
                                                                                </div>
                                                                            </div>
                                                                            {/* Skeleton for connected text */}
                                                                            <p className="text-white"><Skeleton width={100} /></p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body d-flex align-items-center justify-content-between">
                                                                        <div>
                                                                            {/* Skeleton for user credit */}
                                                                            <p className="fs-4 m-0">Credits : <Skeleton width={50} /></p>
                                                                            {/* Skeleton for connected at */}
                                                                            <p className="fs-5 m-0">Connected at : <Skeleton width={100} /></p>
                                                                        </div>
                                                                        {/* Skeleton for Disconnect or SubmitOTP */}
                                                                    </div>
                                                                </div>
                                                        }
                                                    </div>
                                                    <div className="col-12 col-lg-6 p-3">
                                                        {
                                                            loading ?
                                                                <Skeleton height={175} />
                                                                :
                                                                <div className="card card-custom border border-primary border-2 py-5">
                                                                    <div className="card-header">
                                                                        <div>
                                                                            {/* Skeleton for user name */}
                                                                            <h3 className="card-title fs-2 text-primary detail">
                                                                                <strong><Skeleton width={150} /></strong>
                                                                            </h3>
                                                                            {/* Skeleton for user phone */}
                                                                            <p><Skeleton width={100} /></p>
                                                                        </div>
                                                                        <div className="card-toolbar">
                                                                            {/* Skeleton for SVG icon */}
                                                                            <div>
                                                                                <div className="dropdown">
                                                                                    <span
                                                                                        className="d-inline-flex align-items-center"
                                                                                        style={{ cursor: 'pointer' }}
                                                                                        data-bs-toggle="dropdown"
                                                                                        aria-expanded="false"
                                                                                    >
                                                                                        <Skeleton width={30} height={30} />
                                                                                    </span>
                                                                                    {/* Skeleton for dropdown */}
                                                                                </div>
                                                                            </div>
                                                                            {/* Skeleton for connected text */}
                                                                            <p className="text-white"><Skeleton width={100} /></p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body d-flex align-items-center justify-content-between">
                                                                        <div>
                                                                            {/* Skeleton for user credit */}
                                                                            <p className="fs-4 m-0">Credits : <Skeleton width={50} /></p>
                                                                            {/* Skeleton for connected at */}
                                                                            <p className="fs-5 m-0">Connected at : <Skeleton width={100} /></p>
                                                                        </div>
                                                                        {/* Skeleton for Disconnect or SubmitOTP */}
                                                                    </div>
                                                                </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                    :
                                    <div className="container w-100 d-flex justify-content-center align-items-center" style={{ height: '500px' }}>
                                        <i className="fas fa-users-slash fs-5x"></i>
                                    </div>
                            }
                        </>
                }
            </div>
        </div>
    )
}

export { Users }