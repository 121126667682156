import { FC } from "react";
import { QueryParameter } from "../../interfaces/QueryParameter";
// import { Link } from "react-router-dom";
import { Link, Element, Events, scrollSpy } from 'react-scroll';

interface DocumentationProps {
    name: string
    endpoint: string
    description: string
    method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'
    apiKey: string
    queryParameters: QueryParameter[]
}

const Documentation: FC<DocumentationProps> = ({ name, endpoint, description, method, apiKey, queryParameters }) => {
    return (
        <div>
            <h1 className="fs-2" style={{ color: "#235C97", paddingTop: "5rem" }}>{name}</h1>
            <div id="api-endpoint-info">
                <p><strong>URL:</strong> {endpoint}</p>
                <p><strong>Description:</strong> {description}</p>
                <p><strong>Method:</strong> {method}</p>
                <p><strong>Headers:</strong></p>
                <div className="ms-10">
                    <p><strong>Accept:</strong> */*</p>
                    <p><strong>Content-Type:</strong> application/json</p>
                    <p><strong>Authorization:</strong> {apiKey}</p>
                </div>
            </div>
            {
                queryParameters.length > 0 &&
                <div id="api-endpoint-parameters">
                    <h1 className="fs-2 text-primary">Query Parameters</h1>
                    <table className="table table-row-dashed table-row-gray-300 gy-7">
                        <thead>
                            <tr className="fw-bolder fs-7 text-gray-800">
                                <th>Field</th>
                                <th>Type</th>
                                <th>Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                queryParameters.map((queryParameter, index) => (
                                    <tr key={index}>
                                        <td>{queryParameter.field}</td>
                                        <td>{queryParameter.type}</td>
                                        <td style={{whiteSpace: "pre-wrap"}}>
                                            {queryParameter.description+" "}
                                            {queryParameter.target && <Link to={queryParameter.target} spy smooth={true} duration={500} className="text-primary cursor-pointer">{queryParameter.target_name}</Link>}
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            }
        </div >
    )
}

export { Documentation }