import { useState, useRef } from "react";
import { Purchase } from "../../../app/pages/purchase";
import { customNumberFormat } from "../../../function/general";
import { GenerateQris } from "../GenerateQris";
import { RootState } from "../../../setup";
import { useSelector, shallowEqual } from "react-redux";
import { buyPackage } from "../../../app/modules/package/PackageCRUD";
import { Modal } from "bootstrap";
import { UserModel } from "../../../app/modules/auth/models/UserModel";
interface PurchaseBuyProps {
    purchase: Purchase
}

const PurchaseBuy: React.FC<PurchaseBuyProps> = ({ purchase }) => {
    const accessToken = useSelector<RootState, string>(
        ({ auth }) => auth.accessToken as string,
        shallowEqual
    );

    const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

    const [loading, setLoading] = useState<boolean>(false);
    const [hash, setHash] = useState<string>('');
    const closeModal = useRef<HTMLButtonElement | null>(null);

    const buy = async (purchase: Purchase) => {
        setLoading(true);
        const response = await buyPackage(accessToken, purchase.code);
        console.log(response)
        if (response.data.success) {
            setLoading(false);
            setHash(response.data.data);
            const modalId = `generate-qris-modal-${response.data.data}`;
            const modalElement = document.getElementById(modalId);
            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();
                closeModal.current?.click();
            }
        }
    }

    return (
        <>
            <button className="btn btn-primary px-6 align-self-center text-nowrap" data-bs-toggle="modal" data-bs-target={`#purchase-buy-modal-${purchase.code}`}>Beli Paket</button>

            <div className="modal fade" tabIndex={-1} id={`purchase-buy-modal-${purchase.code}`}>
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content" style={{ "padding": "2rem" }}>
                        <div style={{ textAlign: "center", fontSize: "17pt", fontWeight: "700" }} className="mb-5 text-primary">{purchase.name}</div>
                        <div className="modal-body" style={{ fontSize: "15px", textAlign: "center" }}>
                            <span>
                                Apakah anda yakin akan membeli Paket <b>{purchase.name}</b> yang berisi
                                <b style={{ fontWeight: "700" }}> {customNumberFormat(purchase.value)} Token </b>
                                dengan harga <b style={{ fontWeight: "700" }}>Rp. {customNumberFormat(purchase.price_reccuring > 0 ? purchase.price_reccuring : purchase.price)}</b> ?
                            </span>
                        </div>
                        <div style={{ textAlign: "center" }}>
                            <button ref={closeModal} className="btn btn-secondary me-1" type="button" data-bs-dismiss="modal">Batal</button>
                            <a href={`https://wa.me/6282330779799?text=ORDER%20PAKET%20WOBBLAZZ%20CORPORATE%0Anama%20corporate:%20${user.data.user_name}%20%0Anama%20paket:%20${purchase.name}%20`}
                                target="_blank"
                                rel="noreferrer"
                                className="indicator-label text-white btn btn-primary"
                            >
                                Beli
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            {
                hash &&
                <GenerateQris key={hash} hash={hash} price={purchase.price} />
            }
        </>
    )
}

export { PurchaseBuy }