import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL

export const GET_DETAIL_BY_USER = `${API_URL}v3/broadcast/get_detail_by_user`

export function getDetailByUser(phone: string, date_start: string, date_end: string) {
    return axios.post(GET_DETAIL_BY_USER,
        {
            phone,
            date_start,
            date_end
        }
    );
}