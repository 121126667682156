import axios from "axios";

export const GET_CHAT_LOG = window.location.host === 'www.otpwa.com' || window.location.host === 'localhost:3011' ? `https://api.otpwa.com/wzcorp/get_chat_log` : `https://api.wooblazz.com/wzcorp/get_chat_log`

export function getChatLog(v_code: string) {
    return axios.post(GET_CHAT_LOG,
        {
            v_code
            // v_code: "lmzylm1c0.5g45nyjw7x4"
        },
    );
}   