import { Disconnect } from "./Disconnect";
import { SubmitOTP } from "./SubmitOTP";
import { AddToken } from "./AddToken";
import { useTimer } from "../../../hooks/Timer";
import { KTSVG } from "../../../_metronic/helpers";
import { User } from "../../../interfaces/User";
import { Link } from "react-router-dom";
import { secondsToTimeFormat, customNumberFormat } from "../../../function/general";
import { formatDate } from "../../../function/general";
import { useEffect, useRef, useState } from "react";
import { UpdateName } from "./UpdateName";
import { checkSession } from "../../../app/modules/whatsapp/WhatsAppCRUD";
import Skeleton from "react-loading-skeleton";
import { FaWhatsapp } from "react-icons/fa";
import { ConnectWhatsapp } from "./ConnectWhatsapp";

interface UserItemProps {
    user: User
}

const UserItem: React.FC<UserItemProps> = ({ user }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [isSession, setIsSession] = useState<boolean>(false);
    const targetDate = new Date(user.corporate_user_date_otp_expired);

    const now = Date.now();
    const targetTime = targetDate.getTime();
    const durationInSeconds = Math.floor((targetTime - now) / 1000);

    const { countDown, resetCountDown } = useTimer(durationInSeconds);

    const prevCorporateUserDateOtpExpiredRef = useRef<string>(user.corporate_user_date_otp_expired);

    useEffect(() => {
        const prevValue = prevCorporateUserDateOtpExpiredRef.current;

        // Check if the previous value is different from the current value
        if (prevValue !== user.corporate_user_date_otp_expired) {
            resetCountDown();
        }

        // Update the ref to the current value for the next render
        prevCorporateUserDateOtpExpiredRef.current = user.corporate_user_date_otp_expired;
    }, [user.corporate_user_date_otp_expired]);

    useEffect(() => {
        setLoading(true);

        const fetchCheckSession = async () => {
            try {
                const response = await checkSession(user.user_phone);
                setIsSession(response.data.status);
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        };

        fetchCheckSession();
    }, [])

    return (
        <div className="card card-custom border border-primary border-2 py-5">
            <div className="card-header">
                <div>
                    <Link to={`detail/${btoa(user.user_phone)}`}>
                        <h3 className="fs-2 text-primary detail"><strong>{user.user_phone}</strong></h3>
                    </Link>

                    <h6>{user.user_name}</h6>
                </div>
                <div className="card-toolbar">
                    {user.corporate_user_status === 1 ? (
                        <div>
                            <div className="d-flex justify-content-end align-items-center">
                                {
                                    (user.user_phone == '6283879615834' || user.user_phone == '628993607375' || user.user_phone == '0')
                                        ? <div></div>
                                        : ( loading )
                                            ? <Skeleton width={37} height={37} borderRadius={50} />
                                            : <FaWhatsapp style={{ fontSize: "2.75rem", color: isSession ? "#50cd89" : "#575757", cursor: 'pointer' }} data-bs-toggle="modal" data-bs-target={`#connect-whatsapp-modal-${user.corporate_user_code}`} />
                                }
                                <div className="dropdown">
                                    <span className="d-inline-flex align-items-center" style={{ cursor: 'pointer' }} data-bs-toggle="dropdown" aria-expanded="false">
                                        <KTSVG path="/media/icons/duotune/general/gen033.svg" className="svg-icon-muted svg-icon-3hx" />
                                    </span>
                                    <ul className="dropdown-menu border border-primary">
                                        <li className="px-5 py-1">
                                            <span
                                                style={{ cursor: 'pointer' }}
                                                data-bs-toggle="modal"
                                                data-bs-target={`#update-name-modal-${user.corporate_user_code}`}
                                            >
                                                Ubah Nama
                                            </span>
                                        </li>
                                        <li className="px-5 py-1">
                                            <Link to={`${btoa(user.corporate_user_code)}/open-api`} className="text-black" target="_blank">
                                                <span>Open API</span>
                                            </Link>
                                        </li>
                                        <li className="px-5 py-1">
                                            <span
                                                style={{ cursor: 'pointer' }}
                                                data-bs-toggle="modal"
                                                data-bs-target={`#disconnect-modal-${user.corporate_user_code}`}
                                            >
                                                Hapus
                                            </span>
                                        </li>
                                    </ul>
                                    <Disconnect user={user} />
                                    <UpdateName user={user} />
                                    <ConnectWhatsapp user={user} setIsSession={setIsSession} />
                                </div>
                            </div>
                            {/* <p className="text-white">Connected</p> */}
                        </div>
                    ) : (
                        <>
                            <div>
                                <KTSVG path="/media/icons/duotune/general/gen013.svg" className="svg-icon-danger svg-icon-3hx d-flex justify-content-end" />
                                {
                                    countDown > 0 ?
                                        (
                                            <p>OTP Expired: {secondsToTimeFormat(countDown)}</p>
                                        )
                                        :
                                        (
                                            <p>OTP Expired</p>
                                        )
                                }
                            </div>
                        </>
                    )}
                </div>
            </div>
            <div className="card-body d-flex align-items-center justify-content-between">
                <div>
                    <p className="fs-4 m-0">Token : {customNumberFormat(user.user_credit)}</p>
                    <p className="fs-5 m-0">Tersambung : {formatDate(user.corporate_user_date_created)} </p>
                </div>
                {
                    user.corporate_user_status === 1 ?
                        <>
                            <AddToken user={user} />
                            <SubmitOTP user={user} usage="disconnect" />
                        </>
                        :
                        <SubmitOTP user={user} usage="connect" />
                }
            </div>
        </div>
    )
}

export { UserItem }