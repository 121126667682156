import { create } from 'zustand';

export type History = {
    code: string;
    broadcast_code: string;
    contact_code: string;
    name: string;
    phone: string;
    message: string;
    date_sent: string;
    status: number;
    date_created: string;
    response_time: number;
}

type UserBroadcastState = {
    histories: History[];
    setHistories: (histories: History[]) => void;
    getHistory: (code: string) => History | undefined;
}

export const useUserBroadcastStore = create<UserBroadcastState>((set, getState) => ({
    histories: [],

    setHistories: (histories) => {
        set({ histories });
    },

    getHistory: (code) => {
        const { histories } = getState();
        return histories.find((history) => history.code === code);
    },
}));