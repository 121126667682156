import { FC, useEffect, useRef, useState } from "react";
import { KTSVG } from "../../_metronic/helpers";
import { useFormik } from "formik";
import * as Yup from 'yup';
import clsx from "clsx";
import { changePassword } from "../../app/modules/auth/redux/AuthCRUD";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "../../setup";
import Swal from "sweetalert2";
import { useDispatch } from 'react-redux'
import { UserModel } from "../../app/modules/auth/models/UserModel";
import * as auth from '../../app/modules/auth/redux/AuthRedux'

const ExpiredModal: FC = () => {

    const modalRef = useRef<HTMLDivElement | null>(null);
    const dispatch = useDispatch()

    const logout = () => {
        Swal.fire({
            title: "Keluar",
            text: "Apakah Anda yakin ingin keluar?",
            icon: "warning",
            showCancelButton: !0,
            buttonsStyling: !1,
            confirmButtonText: "Ya, keluar!",
            cancelButtonText: "Tidak",
            customClass: {
                confirmButton: "btn btn-danger",
                cancelButton: "btn btn-active-light",
            },
            reverseButtons: true,
            heightAuto: false,
            preConfirm: () => {
                dispatch(auth.actions.logout())
                localStorage.removeItem('corporate-user-storage')
                localStorage.removeItem('users-storage')
            }
        })
    }

    const handleWhatsAppChat = () => {
        const phoneNumber = '6282330779799'; // Ganti dengan nomor WhatsApp yang diinginkan
        const message = `Halo Admin, saya ingin memperpanjang masa aktif akun ${window.location.host === 'www.otpwa.com' || window.location.host === 'localhost:3011' ? 'OTP Whatsapp' : 'Wooblazz Coporate'}!`; // Ganti dengan pesan yang diinginkan
        const whatsappLink = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
        window.location.href = whatsappLink;
    };

    return (
        <div className="modal fade show" tabIndex={-1} id="modal-expired" ref={modalRef} style={{ display: "block", backgroundColor: "rgba(0,0,0,0.4)" }} aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header border-0 pb-0">
                        <h5 className="modal-title">Masa Aktif Kamu Sudah Habis</h5>
                    </div>
                    <div className="modal-body">
                        <span className="fs-5">Silahkan perpanjang masa aktif akun kamu</span>
                    </div>
                    <div className="modal-footer border-0">
                        <button className="btn btn-light" onClick={logout}>Keluar</button>
                        <button className="btn btn-success" onClick={handleWhatsAppChat}>Chat Admin</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { ExpiredModal }