import { KTSVG } from "../../../../_metronic/helpers";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import OtpInput from "react-otp-input";
import { useState, useRef } from "react";
import { User } from "../../../../interfaces/User";
import { useUserStore } from "../../../../stores/UserStore";
import { useNotificationStore } from "../../../../stores/NotificationStore";
import { useTimer } from "../../../../hooks/OtpTimer";
import { useSelector, shallowEqual } from "react-redux";
import { RootState } from "../../../../setup";
import { connect, submit_otp } from "../../../../app/modules/users/UserCRUD";
import { disconnect } from "../../../../app/modules/users/UserCRUD";
interface SubmitOTPProps {
    user: User
    usage: 'connect' | 'disconnect'
}

function formatCountdown(countDown: number) {
    const minutes = Math.floor(countDown / 60);
    const seconds = countDown % 60;
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
}

const SubmitOTP: React.FC<SubmitOTPProps> = ({ user, usage }) => {
    const accessToken = useSelector<RootState, string>(
        ({ auth }) => auth.accessToken as string,
        shallowEqual
    );

    const [modalBtnLoading, setModalBtnLoading] = useState<boolean>(false);

    const { countDown, resetCountDown } = useTimer(user.corporate_user_date_created, 60);

    const { users, setUsers } = useUserStore()
    const { setNotification } = useNotificationStore()

    const [otp, setOtp] = useState<string>('');
    const [otpError, setOtpError] = useState<boolean>(false);

    const closeOtpBtnRef = useRef<HTMLButtonElement>(null);

    const submitOTP = async (phone: string, otp: string) => {
        setOtp('')
        setModalBtnLoading(true);
        if (usage === 'connect') {
            try {
                const response = await submit_otp(accessToken, phone, otp);
                if (response.data.success) {
                    setTimeout(() => {
                        setNotification({
                            title: "Agen Terverifikasi",
                            message: `Agen ${phone} terverifikasi`
                        });
                        closeOtpBtnRef.current?.click();
                        setModalBtnLoading(false)
                        setUsers(users.map(user => {
                            if (user.user_phone === phone) {
                                user.corporate_user_status = 1;
                            }
                            return user;
                        }));
                    }, 1000);
                } else {
                    throw new Error("Gagal memverifikasi OTP");
                }
            } catch (error) {
                setTimeout(() => {
                    setModalBtnLoading(false);
                    setOtpError(true);
                }, 1000);
            }
        } else {
            try {
                const response = await disconnect(accessToken, phone);
                if (response.data.success) {
                    setTimeout(() => {
                        setModalBtnLoading(false);
                        closeOtpBtnRef.current?.click();
                        setUsers(users.filter(user => user.user_phone !== phone));
                        setNotification({
                            title: "Agen Terhapus",
                            message: `Agen dengan telepon ${phone} berhasil dihapus`
                        });
                    }, 1000);
                } else {
                    throw new Error("Gagal menghapus agen");
                }
            } catch (error) {
                throw error;
            }
        }
    }

    const resendOTP = async (accessToken: string, phone: string) => {
        setModalBtnLoading(true)
        try {
            const response = await connect(accessToken, phone);
            if (response.data.success) {
                setTimeout(() => {
                    setUsers(users.map(user => {
                        if (user.user_phone === phone) {
                            user.corporate_user_date_otp_expired = response.data.data.corporate_user_date_otp_expired;
                        }
                        return user;
                    }))
                    setNotification({
                        title: "OTP Dikirim ulang",
                        message: `OTP dikirim ulang ke ${phone}`
                    });
                    setModalBtnLoading(false)
                }, 1000);
            } else {
                throw new Error("Gagal mengirim ulang OTP");
            }
        } catch (error) {
            throw error;
        } finally {
            resetCountDown();
        }
    }

    return (
        <>
            {
                usage === 'connect' &&
                <button type="button"
                    className="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target={`#otp-modal-${user.corporate_user_code}`}
                >
                    Masukkan OTP
                </button>
            }
            <div className="modal fade" tabIndex={-1} id={`otp-modal-${user.corporate_user_code}`}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Masukkan OTP</h4>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="text-center mb-10">
                                {otpError &&
                                    <div className='mb-lg-15 alert alert-danger w-50 mx-auto'>
                                        <h5 className='alert-text font-weight-bold'>OTP salah</h5>
                                    </div>
                                }
                                <img alt="Logo" className="mh-125px" src={toAbsoluteUrl("/media/svg/misc/smartphone.svg")} />
                                <p className="fs-5 p-5 pb-0">
                                    <strong>Masukkan kode verifikasi yang kami kirimkan ke</strong>
                                </p>
                                <p className="fs-3"><strong>+{user.user_phone}</strong></p>
                                <OtpInput
                                    value={otp}
                                    onChange={setOtp}
                                    numInputs={4}
                                    inputType="tel"
                                    renderSeparator={<span></span>}
                                    containerStyle={{ justifyContent: 'center' }}
                                    renderInput={(props) =>
                                        <input
                                            {...props}
                                            maxLength={1}
                                            className="form-control form-control-solid h-60px mw-60px fs-2hx text-center border-active-primary mx-1 my-2"
                                            style={{ width: '16%' }}
                                            disabled={modalBtnLoading}
                                        />}
                                />
                                <div className="text-center fw-bold fs-5 mt-5">
                                    <span className="text-muted me-1">Tidak menerima kode ?</span>
                                    <span
                                        className="link-primary fw-bolder fs-5 me-1"
                                        style={{ cursor: countDown > 0 ? 'not-allowed' : 'pointer' }}
                                        id={`resendOTP-${user.corporate_user_code}`}
                                        role="button"
                                        tabIndex={countDown ? -1 : 0}
                                        onClick={countDown ? undefined : () => {
                                            resendOTP(accessToken, user.user_phone);
                                        }}
                                        data-kt-indicator={modalBtnLoading ? 'on' : 'off'}
                                    >
                                        <span className="indicator-label">{countDown === 0 ? 'Kirim Ulang' : `${formatCountdown(countDown)}`}</span>
                                        <span className="indicator-progress">
                                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                    </span>
                                    <span className="text-primary fw-bolder me-1" id="counter">
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                ref={closeOtpBtnRef}
                                type="button"
                                className="btn btn-light"
                                data-bs-dismiss="modal"
                                onClick={() => {
                                    setOtpError(false)
                                }}
                            >
                                Batal
                            </button>
                            <button type="button" data-kt-indicator={modalBtnLoading ? 'on' : 'off'} className="btn btn-primary d-none" onClick={() => { submitOTP(user.user_phone, otp) }}
                                {...otp.length !== 4 && { disabled: true }}
                                {...otp.length === 4 && submitOTP(user.user_phone, otp)}
                            >
                                <span className="indicator-label">Submit</span>
                                <span className="indicator-progress">Harap tunggu...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export { SubmitOTP }